<template>
	<div id="search">
		<UIBox>
			<template v-slot:title>
				<span class="bold">Search</span>
			</template>
			<template>
				<div>
					<!-- Search Bar -->
					<vue-typeahead-bootstrap class="search" inputClass="search-icon" :autoClose="false" v-model="temp" :data="searchResults ? searchResults : []" @input="fetchSuggestions"/>
				</div>
				<div class="table-fullwidth table-icons">
					<!-- Search Results Table -->
					<b-table :items="searchResults" :fields="searchFields" :per-page="perPageSearch" :current-page="currentPageSearch" aria-controls="search-controls">
						
						<template #cell(launchInDialer)="launch">
							<b-button @click="" class="icon">
								<LaunchinDialerSVG />
							</b-button>
						</template>
						
						<template #cell(phoneNumber)="number">
							<div class="search-number">
								<div class="number-icon">
									<b-button @click="" class="icon">
										<PhoneSVG />
									</b-button>
								</div>
								<div class="number">{{ number.unformatted }}</div>
							</div>
						</template>
						
						<template #cell(url)="openurl">
							<b-button @click="" class="icon">
								<ExtlinkSVG />
							</b-button>
						</template>
						
					</b-table>
				</div>
				<!-- Bottom Input -->
				<div class="bottom-input">
					
					<div class="per-page">
						<div class="select">
							Per Page
							<select class="per-page-drop" v-model="perPageSearch" @change="perpageChange">
								<option value="5">5</option>
								<option value="10">10</option>
								<option value="15">15</option>
								<option value="20">20</option>
							</select>
						</div>
					</div>
					
					<template>
						<div class="pagin">
							<b-pagination v-model="currentPageSearch" :total-rows="rowSearch" :per-page="perPageSearch" aria-controls="search-controls">
								<template #first-text>
									<PaginleftdoubleSVG />
								</template>
								<template #prev-text>
									<PaginleftSVG />
								</template>
								<template #next-text>
									<PaginrightSVG />
								</template>
								<template #last-text>
									<PaginrightdoubleSVG />
								</template>
							</b-pagination>
						</div>
					</template>
					
					<div class="current-page">Current Page: {{ currentPageSearch }}</div>

				</div>
			</template>
		</UIBox>
	</div>
</template>

<script setup>
import { ref, computed } from 'vue'
import UIBox from './uibox.vue'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'

import LaunchinDialerSVG from './SVG-icons/launchindialerSVG.vue'
import ExtlinkSVG from './SVG-icons/extlinkSVG.vue'
import PhoneSVG from './SVG-icons/phoneSVG.vue'
import PaginleftdoubleSVG from './SVG-icons/paginleftdoubleSVG.vue';
import PaginleftSVG from './SVG-icons/paginleftSVG.vue';
import PaginrightdoubleSVG from './SVG-icons/paginrightdoubleSVG.vue';
import PaginrightSVG from './SVG-icons/paginrightSVG.vue';

const count = ref(0)

const perPageSearch = ref(10)
const currentPageSearch = ref(1)

const rowSearch = computed(() => {
	return searchItems.length
})

let temp = '';
let searchResults = [];

async function fetchSuggestions() {
      try {
        if (this.temp.length > 2) { // Trigger search when input length is greater than 2
          this.searchResults = null;
          let response = await axios
            .get(`https://123avatars.com/v2/search`, { params: { query: this.temp } });
            this.searchResults = response.data.results.map(res => res.company_name);
            console.log('searchResults: ', this.searchResults);
        }          
      } catch(error) {
            console.error('Error fetching search results:', error);  
      }
    }

function perpageChange() {}

const searchFields = [{
		key: 'launchInDialer',
		label: '',
		variant: 'bold',
	},
	{
		key: 'ownerName',
		label: 'Owner Name'
	},
	{
		key: 'company',
		label: 'Company'
	},
	{
		key: 'phoneNumber',
		label: 'Phone Number'
	},
	{
		key: 'totalTalk',
		label: 'Total Talk Time'
	},
	{
		key: 'email',
		label: 'Email'
	},
	{
		key: 'url',
		label: 'URL'
	}
]



const searchItems = [{
		launchInDialer: 'Launch Link',
		ownerName: 'First Last',
		company: 'Company Name',
		phoneNumber: 1231234567,
		totalTalk: '1h 23m',
		email: 'hello@email.com',
		url: 'www.hello.com'
	},
	{
		launchInDialer: 'Launch Link',
		ownerName: 'First Last',
		company: 'Company Name',
		phoneNumber: 1231234567,
		totalTalk: '1h 23m',
		email: 'hello@email.com',
		url: 'www.hello.com'
	},
	{
		launchInDialer: 'Launch Link',
		ownerName: 'First Last',
		company: 'Company Name',
		phoneNumber: 1231234567,
		totalTalk: '1h 23m',
		email: 'hello@email.com',
		url: 'www.hello.com'
	},
	{
		launchInDialer: 'Launch Link',
		ownerName: 'First Last',
		company: 'Company Name',
		phoneNumber: 1231234567,
		totalTalk: '1h 23m',
		email: 'hello@email.com',
		url: 'www.hello.com'
	},
	{
		launchInDialer: 'Launch Link',
		ownerName: 'First Last',
		company: 'Company Name',
		phoneNumber: 1231234567,
		totalTalk: '1h 23m',
		email: 'hello@email.com',
		url: 'www.hello.com'
	},
	{
		launchInDialer: 'Launch Link',
		ownerName: 'First Last',
		company: 'Company Name',
		phoneNumber: 1231234567,
		totalTalk: '1h 23m',
		email: 'hello@email.com',
		url: 'www.hello.com'
	},
	{
		launchInDialer: 'Launch Link',
		ownerName: 'First Last',
		company: 'Company Name',
		phoneNumber: 1231234567,
		totalTalk: '1h 23m',
		email: 'hello@email.com',
		url: 'www.hello.com'
	},
	{
		launchInDialer: 'Launch Link',
		ownerName: 'First Last',
		company: 'Company Name',
		phoneNumber: 1231234567,
		totalTalk: '1h 23m',
		email: 'hello@email.com',
		url: 'www.hello.com'
	},
	{
		launchInDialer: 'Launch Link',
		ownerName: 'First Last',
		company: 'Company Name',
		phoneNumber: 1231234567,
		totalTalk: '1h 23m',
		email: 'hello@email.com',
		url: 'www.hello.com'
	},
	{
		launchInDialer: 'Launch Link',
		ownerName: 'First Last',
		company: 'Company Name',
		phoneNumber: 1231234567,
		totalTalk: '1h 23m',
		email: 'hello@email.com',
		url: 'www.hello.com'
	},
	{
		launchInDialer: 'Launch Link',
		ownerName: 'First Last',
		company: 'Company Name',
		phoneNumber: 1231234567,
		totalTalk: '1h 23m',
		email: 'hello@email.com',
		url: 'www.hello.com'
	},
	{
		launchInDialer: 'Launch Link',
		ownerName: 'First Last',
		company: 'Company Name',
		phoneNumber: 1231234567,
		totalTalk: '1h 23m',
		email: 'hello@email.com',
		url: 'www.hello.com'
	},
	{
		launchInDialer: 'Launch Link',
		ownerName: 'First Last',
		company: 'Company Name',
		phoneNumber: 1231234567,
		totalTalk: '1h 23m',
		email: 'hello@email.com',
		url: 'www.hello.com'
	},
	{
		launchInDialer: 'Launch Link',
		ownerName: 'First Last',
		company: 'Company Name',
		phoneNumber: 1231234567,
		totalTalk: '1h 23m',
		email: 'hello@email.com',
		url: 'www.hello.com'
	},
	{
		launchInDialer: 'Launch Link',
		ownerName: 'First Last',
		company: 'Company Name',
		phoneNumber: 1231234567,
		totalTalk: '1h 23m',
		email: 'hello@email.com',
		url: 'www.hello.com'
	},
	{
		launchInDialer: 'Launch Link',
		ownerName: 'First Last',
		company: 'Company Name',
		phoneNumber: 1231234567,
		totalTalk: '1h 23m',
		email: 'hello@email.com',
		url: 'www.hello.com'
	},
	{
		launchInDialer: 'Launch Link',
		ownerName: 'First Last',
		company: 'Company Name',
		phoneNumber: 1231234567,
		totalTalk: '1h 23m',
		email: 'hello@email.com',
		url: 'www.hello.com'
	},
	{
		launchInDialer: 'Launch Link',
		ownerName: 'First Last',
		company: 'Company Name',
		phoneNumber: 1231234567,
		totalTalk: '1h 23m',
		email: 'hello@email.com',
		url: 'www.hello.com'
	}
]
</script>