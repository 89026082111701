<template>
  <div id="app">
    <div class="top-header">
      <div class="status-indicator">
        <button class="button-start-dialer" :class="{ 'button-green': dialerStatus === 'Logged Off Dialer', 'button-red': dialerStatus === 'Logged Into Dialer' }" @click="toggleDialer">{{ dialerButtonLabel }}</button>
        <span>{{ dialerStatus }}</span>
        <span class="stopwatch" :class="{ 'logged-in': dialerStatus === 'Logged Into Dialer', 'logged-out': dialerStatus === 'Logged Off Dialer' }">
          <span>{{ hours }}h : {{ minutes }}m : {{ seconds }}s</span>
        </span>
        <span v-if="dialerStatus === 'Logged Into Dialer'" class="phone-status">{{ phoneStatus }}</span>
      </div>
      <h1>Personal Dashboard</h1>
      <span class="icons">
        <img src="https://i.imgur.com/f1d9ZlW.png" alt="Webphone" class="icon" @click="toggleDialpad">
        <img src="https://i.imgur.com/fh1vV1P.png" alt="Voicemail" class="icon">
      </span>
    </div>
    <div class="container">
      <div class="menu">
        <ul>
          <!-- <li><a href="#" @click="showPage('search')">Search</a></li> -->
          <li :class="{ active: currentPage === 'dialer' }"><a href="#" @click="showPage('dialer')">Dialer</a></li>
          <!-- <li><a href="#" @click="showPage('chatWithManager')">Chat with Manager</a></li>
          <li :class="{ active: currentPage === 'folders' }"><a href="#" @click="showPage('folders')">Folders</a></li>
          <li :class="{ active: currentPage === 'askAI' }"><a href="#" @click="showPage('askAI')">Ask Our AI</a></li>
          <li :class="{ active: currentPage.includes('reports') }">
            <a href="#" @click="toggleReportsMenu">Reports</a>
            <ul v-if="showReportsMenu" class="submenu">
              <li><a href="#" @click="showReport('callsReport')">Calls Report</a></li>
              <li><a href="#" @click="showReport('voicemails')">Voicemails</a></li>
              <li><a href="#" @click="showReport('averagesPerSale')">Averages Per Sale</a></li>
              <li><a href="#" @click="showReport('personalScoreboard')">Personal Scoreboard</a></li>
              <li><a href="#" @click="showReport('onboardingReport')">Onboarding Report</a></li>
            </ul>
          </li>
          <li :class="{ active: currentPage.includes('settings') }">
            <a href="#" @click="toggleSettingsMenu">Settings</a>
            <ul v-if="showSettingsMenu" class="submenu">
              <li><a href="#" @click="showSetting('myProfile')">My Profile</a></li>
              <li><a href="#" @click="showSetting('setUpVoicemail')">Set Up My Voicemail</a></li>
            </ul>
          </li> -->
        </ul>
      </div>
      <div class="content">
        <div v-if="currentPage === 'dialer'" class="main-container">
          <div class="button-container">
            <button class="button" @click="showScheduleCallbackModal">Schedule Callback</button>
            <button class="button" @click="openSignUpProspect">Sign Up Prospect</button>
            <button class="button" @click="openSalesScript">Sales Script</button>
            <button class="button" @click="showDispositionModal">Disposition Call</button>
          </div>
          <div class="main-content">
            <div class="left-column">
              <div class="section-box">
                <h2>Prospects Marketing Information</h2>
                <div class="info-box">
                  <table>
                    <tr>
                      <td class="label">Click Date:</td>
                      <td class="value">{{ prospectsInfo.clickDate }}</td>
                      <td class="label">Talk Time:</td>
                      <td class="value">{{ prospectsInfo.talkTime }}</td>
                    </tr>
                    <tr>
                      <td class="label">Lead Shelflife:</td>
                      <td class="value">{{ prospectsInfo.leadShelflife }}</td>
                      <td class="label">Calls:</td>
                      <td class="value">{{ prospectsInfo.calls }}</td>
                    </tr>
                    <tr>
                      <td class="label">Texts:</td>
                      <td class="value">{{ prospectsInfo.texts }}</td>
                      <td class="label">Voicemails:</td>
                      <td class="value">{{ prospectsInfo.voicemails }}</td>
                    </tr>
                    <tr>
                      <td class="label">Emails:</td>
                      <td class="value">{{ prospectsInfo.emails }}</td>
                      <td class="label">Calls with Humans:</td>
                      <td class="value">{{ prospectsInfo.callsWithHumans }}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="section-box">
                <h2>Company Profile</h2>
                <div class="profile-box">
                  <table>
                    <tr>
                      <td colspan="2" class="company-name">{{ companyProfile.name }}</td>
                    </tr>
                    <tr>
                      <td class="label">Phone:</td>
                      <td class="value">{{ companyProfile.phone }}</td>
                      <td class="label">Google Rating:</td>
                      <td class="value">{{ companyProfile.rating }}</td>
                    </tr>
                    <tr>
                      <td class="label">Address:</td>
                      <td class="value">{{ companyProfile.address }}</td>
                      <td class="label">City:</td>
                      <td class="value">{{ companyProfile.city }}</td>
                    </tr>
                    <tr>
                      <td class="label">State:</td>
                      <td class="value">{{ companyProfile.state }}</td>
                      <td class="label">Zipcode:</td>
                      <td class="value">{{ companyProfile.zipcode }}</td>
                    </tr>
                    <tr>
                      <td colspan="4" class="owner-info-heading">Owner’s Info</td>
                    </tr>
                    <tr>
                      <td class="label">Owner’s Name:</td>
                      <td class="value editable" contenteditable="true" data-placeholder="Enter Name">{{ companyProfile.ownerName }}</td>
                    </tr>
                    <tr>
                      <td class="label">Owner’s Cell:</td>
                      <td class="value editable" contenteditable="true" data-placeholder="Enter Cell">{{ companyProfile.ownerCell }}</td>
                    </tr>
                    <tr>
                      <td class="label">Owner’s Email:</td>
                      <td class="value editable" contenteditable="true" data-placeholder="Enter Email">{{ companyProfile.ownerEmail }}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="section-box">
                <h2>Notes</h2>
                <div class="notes-box">
                  <table>
                    <tr>
                      <th class="centered">Date & Time</th>
                      <th>Note</th>
                      <th class="centered">User</th>
                    </tr>
                    <tr v-for="note in sortedNotes" :key="note.date">
                      <td class="centered">{{ note.date }}</td>
                      <td>{{ note.note }}</td>
                      <td class="centered">{{ note.user }}</td>
                    </tr>
                  </table>
                </div>
                <button class="button" @click="showAddNoteModal">Add Note</button>
              </div>
              <div class="section-box">
                <h2>Calls</h2>
                <div class="calls-box">
                  <table>
                    <tr>
                      <th class="centered">Date & Time</th>
                      <th class="centered">Length</th>
                      <th class="centered">Action</th>
                    </tr>
                    <tr v-for="call in calls" :key="call.date">
                      <td class="centered">{{ call.date }}</td>
                      <td class="centered">{{ call.length }}</td>
                      <td class="centered"><button @click="playCall(call.recording)">Play</button></td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="section-box">
                <h2>Thread</h2>
                <div class="thread-box">
                  <div v-for="message in messages" :class="['message', message.type]">
                    <strong>{{ message.type === 'incoming' ? companyProfile.name : 'You' }}</strong>
                    <span>{{ message.date }}</span>: {{ message.text }}
                  </div>
                  <div class="input-container">
                    <input type="text" v-model="newMessage" placeholder="Type a message..." />
                    <button @click="sendMessage">Send</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="right-column">
              <div class="blue-container">
                <div class="section-box stats-box">
                  <h2>Stats</h2>
                  <div class="stats-content">
                    <table>
                      <tr>
                        <th></th>
                        <th>Today</th>
                        <th>This Week</th>
                      </tr>
                      <tr>
                        <td class="label">Calls:</td>
                        <td class="value">20</td>
                        <td class="value">200</td>
                      </tr>
                      <tr>
                        <td class="label">Voicemails:</td>
                        <td class="value">20</td>
                        <td class="value">200</td>
                      </tr>
                      <tr>
                        <td class="label">Calls with Humans:</td>
                        <td class="value">20</td>
                        <td class="value">200</td>
                      </tr>
                      <tr>
                        <td class="label">Talk Time:</td>
                        <td class="value">2hr 21m</td>
                        <td class="value">21hr 21m</td>
                      </tr>
                      <tr>
                        <td class="label">Dialer Time:</td>
                        <td class="value">7hr 47m</td>
                        <td class="value">32hr 41m</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="section-box sales-box">
                  <h2>Sales</h2>
                  <div class="sales-content">
                    <table>
                      <tr>
                        <th></th>
                        <th>Today</th>
                        <th>This Week</th>
                      </tr>
                      <tr>
                        <td class="label">My Sales:</td>
                        <td class="value">2</td>
                        <td class="value">17</td>
                      </tr>
                      <tr>
                        <td class="label">Team Sales:</td>
                        <td class="value">20</td>
                        <td class="value">97</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div v-if="showNotification" class="notification">
                <div class="notification-content">
                  <h3>Team Sale</h3>
                  <p class="sales-number"># {{ salesNumber }}</p>
                  <p>Just Came In</p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="showDialpad" class="dialpad-container">
            <template>
              <div class="container">
                <div class="row">
                  <div class="col-4">
                    <div class="video-container">
                      <video id="localVideo" autoplay muted></video>
                      <video id="remoteVideo" autoplay></video>
                    </div>
                    <input type="text" class="form-control" placeholder="Enter phone number or SIP user" v-model="callDestination">
                    <div class="dialpad">
                      <div class="row" v-for="row in dialpad" :key="row">
                        <div class="col" v-for="key in row" :key="key">
                          <button class="btn btn-light btn-lg" @click="dialPadPress(key)">{{ key }}</button>
                        </div>
                      </div>
                    </div>
                    <button class="btn btn-primary btn-lg mt-3" @click="makeCall">Call</button>
                  </div>
                  <div class="col-8">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="settings-tab" data-bs-toggle="tab" data-bs-target="#settings" type="button" role="tab" aria-controls="settings" aria-selected="true">Settings</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="media-tab" data-bs-toggle="tab" data-bs-target="#media" type="button" role="tab" aria-controls="media" aria-selected="false">Media settings</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="history-tab" data-bs-toggle="tab" data-bs-target="#history" type="button" role="tab" aria-controls="history" aria-selected="false">History</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="wsMessages-tab" data-bs-toggle="tab" data-bs-target="#wsMessages" type="button" role="tab" aria-controls="wsMessages" aria-selected="false">wsMessages</button>
                      </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                      <div class="tab-pane fade show active" id="settings" role="tabpanel" aria-labelledby="settings-tab">
                        <form @submit.prevent="connect">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="includeVideo" id="includeVideo">
                            <label class="form-check-label" for="includeVideo">Include Video</label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="enableDebugging" id="enableDebugging">
                            <label class="form-check-label" for="enableDebugging">Enable Debugging</label>
                          </div>
                          <div class="mb-3">
                            <label class="form-label">Authentication</label>
                            <div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" id="credential" value="credential" v-model="authentication">
                                <label class="form-check-label" for="credential">Credential</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" id="token" value="token" v-model="authentication">
                                <label class="form-check-label" for="token">Token</label>
                              </div>
                            </div>
                          </div>
                          <div class="mb-3">
                            <input type="text" class="form-control" placeholder="SIP Username" v-model="sipUsername">
                          </div>
                          <div class="mb-3">
                            <input type="password" class="form-control" placeholder="Password" v-model="password">
                          </div>
                          <div class="mb-3">
                            <input type="text" class="form-control" placeholder="Caller ID Name" v-model="callerIdName">
                          </div>
                          <div class="mb-3">
                            <input type="text" class="form-control" placeholder="Caller ID Number" v-model="callerIdNumber">
                          </div>
                          <div class="mb-3">
                            <input type="text" class="form-control" placeholder="Client State" v-model="clientState">
                          </div>
                          <button type="submit" class="btn btn-primary">Connect</button>
                        </form>
                      </div>
                      <div class="tab-pane fade" id="media" role="tabpanel" aria-labelledby="media-tab">Media settings content</div>
                      <div class="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history-tab">History content</div>
                      <div class="tab-pane fade" id="wsMessages" role="tabpanel" aria-labelledby="wsMessages-tab">wsMessages content</div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div v-if="currentPage !== 'dialer'">
          <h2>{{ pageTitle }}</h2>
        </div>
      </div>
    </div>

    <!-- Add Note Modal -->
    <div id="addNoteModal" class="modal" v-if="showNoteModal">
      <div class="modal-content">
        <span class="close" @click="hideAddNoteModal">&times;</span>
        <h2 class="modal-title">Add Note</h2>
        <textarea v-model="newNoteText" placeholder="Enter note here..." rows="5"></textarea>
        <button @click="saveNote">Save Note</button>
      </div>
    </div>

    <!-- Schedule Callback Modal -->
    <div id="scheduleCallbackModal" class="modal" v-if="showCallbackModal">
      <div class="modal-content">
        <span class="close" @click="hideScheduleCallbackModal">&times;</span>
        <h2 class="modal-title">Set Up Callback</h2>
        <div class="modal-body">
          <div class="left-column">
            <h3>Prospect's Information</h3>
            <p>Company: <span>{{ companyProfile.name }}</span></p>
            <p>Time Zone: <span>{{ prospectTimezone }}</span></p>
            <p>Current Time: <span>{{ getCurrentProspectTime }}</span></p>
            <label for="callbackDateTime">Callback Date and Time:</label>
            <input type="datetime-local" id="callbackDateTime" v-model="callbackDateTime">
          </div>
          <div class="right-column">
            <h3>Your Information</h3>
            <p>Time Zone: <span>{{ userTimezone }}</span></p>
            <p>Local Time for Callback: <span>{{ userLocalTime }}</span></p>
            <p>Days Away: <span>{{ daysAway }}</span></p>
          </div>
        </div>
        <button @click="setCallback">Set Callback</button>
      </div>
    </div>

    <!-- Disposition Modal -->
    <div id="dispositionModal" class="modal" v-if="showDisposition">
      <div class="modal-content">
        <span class="close" @click="hideDispositionModal">&times;</span>
        <h2 class="modal-title">Disposition Call</h2>
        <div class="folders-checkboxes">
          <div v-for="folder in folders" :key="folder.id">
            <input type="checkbox" :id="'folder-' + folder.id" :value="folder.id" v-model="selectedFolders">
            <label :for="'folder-' + folder.id">{{ folder.name }}</label>
          </div>
        </div>
        <button @click="saveDisposition">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPage: "dialer",
      showReportsMenu: false,
      showSettingsMenu: false,
      showNoteModal: false,
      showCallbackModal: false,
      showDisposition: false,
      showNotification: false,
      showDialpad: false,
      newNoteText: "",
      newMessage: "",
      newChatMessage: "",
      callbackDateTime: "",
      userLocalTime: "--",
      daysAway: "--",
      salesNumber: 1,
      searchQuery: "",
      searchResults: [],
      chatMessages: [
        {
          type: "incoming",
          text: "Hello, how can I help you today?",
          date: "8/30/2021 10:30 AM"
        },
        {
          type: "outgoing",
          text: "I have a question about my performance.",
          date: "8/30/2021 10:32 AM"
        }
      ],
      prospectTimezone: "America/New_York",
      userTimezone: "America/Los_Angeles",
      prospectsInfo: {
        clickDate: "8/30/2023",
        leadShelflife: "332 Days",
        texts: 21,
        emails: 15,
        talkTime: "1hr 1m",
        calls: 20,
        voicemails: 17,
        callsWithHumans: 3
      },
      companyProfile: {
        name: "ABC Plumbing",
        phone: "(949) 444-5999",
        address: "123 State Street",
        city: "Laguna Beach",
        state: "California",
        zipcode: "92651",
        rating: 4.7,
        ownerName: "Mike Johnson",
        ownerCell: "(949) 444-5999",
        ownerEmail: "russ@abcplumbing"
      },
      notes: [
        {
          date: "8/30/2021",
          note: "test note for abc plumbing",
          user: "Chris Jones"
        }
      ],
      calls: [
        {
          date: "8/30/2021",
          recording: "test note for abc plumbing",
          length: "21min 47sec",
          user: "Chris Jones"
        },
        {
          date: "8/29/2021",
          recording: "test note for abc plumbing",
          length: "19min 12sec",
          user: "Chris Jones"
        },
        {
          date: "8/28/2021",
          recording: "test note for abc plumbing",
          length: "15min 30sec",
          user: "Chris Jones"
        },
        {
          date: "8/27/2021",
          recording: "test note for abc plumbing",
          length: "25min 10sec",
          user: "Chris Jones"
        },
        {
          date: "8/26/2021",
          recording: "test note for abc plumbing",
          length: "18min 5sec",
          user: "Chris Jones"
        },
        {
          date: "8/25/2021",
          recording: "test note for abc plumbing",
          length: "22min 30sec",
          user: "Chris Jones"
        },
        {
          date: "8/24/2021",
          recording: "test note for abc plumbing",
          length: "20min 15sec",
          user: "Chris Jones"
        },
        {
          date: "8/23/2021",
          recording: "test note for abc plumbing",
          length: "17min 45sec",
          user: "Chris Jones"
        },
        {
          date: "8/22/2021",
          recording: "test note for abc plumbing",
          length: "19min 55sec",
          user: "Chris Jones"
        },
        {
          date: "8/21/2021",
          recording: "test note for abc plumbing",
          length: "16min 25sec",
          user: "Chris Jones"
        }
      ],
      messages: [
        {
          type: "incoming",
          text: "Hello, how can I help you today?",
          date: "8/30/2021 10:30 AM"
        },
        {
          type: "outgoing",
          text: "I'm interested in your services.",
          date: "8/30/2021 10:32 AM"
        }
      ],
      dialerStatus: "Logged Off Dialer",
      dialerButtonLabel: "Start Dialer",
      dialerStatusClass: "status-logged-off",
      phoneStatus: "Ringing",
      phoneStatuses: ["Ringing", "Connected", "Hung Up"],
      currentPhoneStatusIndex: 0,
      folders: [
        { id: 1, name: "Starred", leads: [] },
        { id: 2, name: "Over 5 Minutes of Talk Time", leads: [] },
        { id: 3, name: "Over 10 Minutes of Talk Time", leads: [] },
        { id: 4, name: "Over 15 Minutes of Talk Time", leads: [] }
      ],
      newFolderName: "",
      selectedFolders: [],
      entriesPerPage: 10,
      onboardingCurrentPage: 1,
      hideCompleted: true,
      entriesOptions: [10, 25, 50, 100, 500, "all"],
      statusOptions: [
        "All",
        "Not Started",
        "No Show",
        "Rescheduled",
        "Started but Not Completed",
        "Completed"
      ],
      statusFilter: "All",
      entries: [
        {
          id: 1,
          signupDate: "2024-05-25",
          signupTime: "10:30 AM",
          companyName: "ABC Corp",
          phone: "123-456-7890",
          onboardingStatus: "Not Started"
        },
        {
          id: 2,
          signupDate: "2024-05-26",
          signupTime: "11:00 AM",
          companyName: "XYZ Inc",
          phone: "098-765-4321",
          onboardingStatus: "Not Started"
        }
      ],
      sortKey: "",
      sortOrder: "asc",
      timer: null,
      hours: 0,
      minutes: 0,
      seconds: 0,
      logOffTimer: null
    };
  },
  computed: {
    filteredEntries() {
      let filtered = this.entries.filter((entry) => {
        let matchesSearch = Object.values(entry).some((val) =>
          val.toString().toLowerCase().includes(this.searchQuery.toLowerCase())
        );
        let matchesStatus =
          this.statusFilter === "All" ||
          entry.onboardingStatus === this.statusFilter;
        let matchesCompleted =
          !this.hideCompleted || entry.onboardingStatus !== "Completed";
        return matchesSearch && matchesStatus && matchesCompleted;
      });

      if (this.sortKey) {
        filtered.sort((a, b) => {
          let result =
            a[this.sortKey] < b[this.sortKey]
              ? -1
              : a[this.sortKey] > b[this.sortKey]
              ? 1
              : 0;
          return this.sortOrder === "asc" ? result : -result;
        });
      }

      let start = (this.onboardingCurrentPage - 1) * this.entriesPerPage;
      let end =
        this.entriesPerPage === "all"
          ? filtered.length
          : start + parseInt(this.entriesPerPage);
      return filtered.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.entries.length / this.entriesPerPage);
    },
    sortedNotes() {
      return this.notes.sort((a, b) => new Date(b.date) - new Date(a.date));
    },
    pageTitle() {
      return this.currentPage.charAt(0).toUpperCase() + this.currentPage.slice(1).replace(/([A-Z])/g, ' $1').trim();
    }
  },
  methods: {
    showPage(page) {
      this.currentPage = page;
      this.showReportsMenu = false; // Hide submenu when switching pages
      this.showSettingsMenu = false; // Hide submenu when switching pages
    },
    toggleReportsMenu() {
      this.showReportsMenu = !this.showReportsMenu;
    },
    showReport(report) {
      this.currentPage = report;
      this.showReportsMenu = false; // Hide submenu after selecting a report
    },
    toggleSettingsMenu() {
      this.showSettingsMenu = !this.showSettingsMenu;
    },
    showSetting(setting) {
      this.currentPage = setting;
      this.showSettingsMenu = false; // Hide submenu after selecting a setting
    },
    sendMessage() {
      if (this.newMessage.trim()) {
        this.messages.push({
          type: "outgoing",
          text: this.newMessage,
          date: new Date().toLocaleString()
        });
        this.newMessage = "";
      }
    },
    sendChatMessage() {
      if (this.newChatMessage.trim()) {
        this.chatMessages.push({
          type: "outgoing",
          text: this.newChatMessage,
          date: new Date().toLocaleString()
        });
        this.newChatMessage = "";
      }
    },
    showAddNoteModal() {
      this.showNoteModal = true;
    },
    hideAddNoteModal() {
      this.showNoteModal = false;
    },
    saveNote() {
      if (this.newNoteText.trim()) {
        this.notes.unshift({
          date: new Date().toLocaleString(),
          note: this.newNoteText,
          user: "Current User"
        });
        this.newNoteText = "";
        this.hideAddNoteModal();
      }
    },
    showScheduleCallbackModal() {
      this.showCallbackModal = true;
    },
    hideScheduleCallbackModal() {
      this.showCallbackModal = false;
    },
    setCallback() {
      if (this.callbackDateTime) {
        const dateTime = new Date(this.callbackDateTime);
        const userTime = new Date(
          dateTime.toLocaleString("en-US", { timeZone: this.userTimezone })
        );
        this.userLocalTime = userTime.toLocaleString();

        const today = new Date();
        const diffTime = Math.abs(dateTime - today);
        this.daysAway = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + " day(s)";

        this.hideScheduleCallbackModal();
      }
    },
    playCall(recording) {
      // Add logic to play the call recording here
      alert("Playing recording: " + recording);
    },
    showNextNotification() {
      this.showNotification = true;
      setTimeout(() => {
        this.showNotification = false;
        this.salesNumber++;
        setTimeout(() => {
          this.showNextNotification();
        }, 5000);
      }, 5000);
    },
    search() {
      // Simulated search results
      this.searchResults = [
        { id: 1, companyName: "ABC Plumbing", phoneNumber: "(949) 444-5999" },
        { id: 2, companyName: "XYZ Electric", phoneNumber: "(123) 456-7890" }
      ];
    },
    openProfile(result) {
      this.companyProfile.name = result.companyName;
      this.companyProfile.phone = result.phoneNumber;
      // Set other profile data as needed
      this.showPage("dialer");
    },
    toggleDialer() {
      if (this.dialerStatus === "Logged Off Dialer") {
        this.dialerStatus = "Logged Into Dialer";
        this.dialerButtonLabel = "Stop Dialer";
        this.dialerStatusClass = "status-logged-in";
        this.startTimer();
        this.resetLogOffTimer();
        this.updatePhoneStatus();
      } else {
        this.dialerStatus = "Logged Off Dialer";
        this.dialerButtonLabel = "Start Dialer";
        this.dialerStatusClass = "status-logged-off";
        this.startLogOffTimer();
        this.resetTimer();
      }
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.seconds++;
        if (this.seconds >= 60) {
          this.seconds = 0;
          this.minutes++;
        }
        if (this.minutes >= 60) {
          this.minutes = 0;
          this.hours++;
        }
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
    },
    resetTimer() {
      this.hours = 0;
      this.minutes = 0;
      this.seconds = 0;
    },
    startLogOffTimer() {
      this.logOffTimer = setInterval(() => {
        this.seconds++;
        if (this.seconds >= 60) {
          this.seconds = 0;
          this.minutes++;
        }
        if (this.minutes >= 60) {
          this.minutes = 0;
          this.hours++;
        }
      }, 1000);
    },
    resetLogOffTimer() {
      clearInterval(this.logOffTimer);
      this.resetTimer();
    },
    openSignUpProspect() {
      window.open("https://www.180ads.com/plans", "_blank");
    },
    openSalesScript() {
      window.open(
        "https://123websites.typeform.com/to/ISIDY4M1?typeform-source=beta.123avatars.com",
        "_blank"
      );
    },
    addFolder() {
      if (this.newFolderName.trim()) {
        this.folders.push({
          id: this.folders.length + 1,
          name: this.newFolderName,
          leads: []
        });
        this.newFolderName = "";
      }
    },
    showDispositionModal() {
      this.showDisposition = true;
    },
    hideDispositionModal() {
      this.showDisposition = false;
    },
    saveDisposition() {
      // Save the disposition with selected folders
      console.log("Selected folders:", this.selectedFolders);
      this.hideDispositionModal();
    },
    sortTable(key) {
      if (this.sortKey === key) {
        this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      } else {
        this.sortOrder = "asc";
      }
      this.sortKey = key;
    },
    autoRemoveRows(entry) {
      if (
        this.statusFilter !== "All" &&
        entry.onboardingStatus !== this.statusFilter
      ) {
        entry.visible = false;
      } else {
        entry.visible = true;
      }
    },
    exportTableToCSV(filename) {
      let csv = [];
      let rows = document.querySelectorAll("table tr");
      for (let i = 0; i < rows.length; i++) {
        let row = [],
          cols = rows[i].querySelectorAll("td, th");
        for (let j = 0; j < cols.length; j++) {
          row.push(cols[j].innerText);
        }
        csv.push(row.join(","));
      }
      let csvFile = new Blob([csv.join("\n")], { type: "text/csv" });
      let downloadLink = document.createElement("a");
      downloadLink.download = filename;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    toggleDialpad() {
      this.showDialpad = !this.showDialpad;
    },
    dialPadPress(key) {
      this.callDestination += key;
    },
    makeCall() {
      this.call = this.telnyxRTC.newCall({
        destinationNumber: this.callDestination,
        callerNumber: this.callerIdNumber,
        video: this.includeVideo
      });

      this.call.on("telnyx.media.update", (media) => {
        console.log("Media update", media);
        const localVideo = document.getElementById("localVideo");
        const remoteVideo = document.getElementById("remoteVideo");
        localVideo.srcObject = media.localStream;
        remoteVideo.srcObject = media.remoteStream;
      });

      this.call.on("telnyx.call.hangup", () => {
        console.log("Call ended");
        this.call = null;
      });

      this.call.invite();
    },
    updatePhoneStatus() {
      if (this.dialerStatus === "Logged Into Dialer") {
        this.phoneStatus = this.phoneStatuses[this.currentPhoneStatusIndex];
        this.currentPhoneStatusIndex =
          (this.currentPhoneStatusIndex + 1) % this.phoneStatuses.length;
        setTimeout(this.updatePhoneStatus, 3000); // Update status every 3 seconds for demo
      }
    }
  },
  mounted() {
    this.showNextNotification();
    const foldersList = document.getElementById("foldersList");
    Sortable.create(foldersList, {
      animation: 150,
      onEnd: (evt) => {
        const movedFolder = this.folders.splice(evt.oldIndex, 1)[0];
        this.folders.splice(evt.newIndex, 0, movedFolder);
        console.log("Reordered folders:", this.folders);
      }
    });
  }
};
</script>

<style scoped>
body {
  font-family: "Arial", sans-serif;
  background-color: #f0f4f8;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1200px;
  margin: 50px auto;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.header {
  text-align: center;
  margin-bottom: 30px;
}

.header h1 {
  margin: 0;
  font-size: 2.5em;
  color: #333;
}

.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
}

th,
td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

th {
  background-color: #f8f9fa;
  cursor: pointer;
  font-weight: 600;
  color: #555;
}

th:hover {
  background-color: #e9ecef;
}

td {
  background-color: #ffffff;
  color: #555;
}

.controls,
.filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.controls input,
.controls select,
.filter-container select {
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s;
}

.controls input {
  width: 600px;
}

.controls input:focus,
.controls select:focus,
.filter-container select:focus {
  border-color: #4caf50;
}

.toggle-container {
  display: flex;
  align-items: center;
}

.toggle-label {
  font-size: 1em;
  margin-right: 10px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.toggle-switch input:checked + .toggle-slider {
  background-color: #4caf50;
}

.toggle-switch input:checked + .toggle-slider:before {
  transform: translateX(26px);
}

.pagination {
  text-align: center;
}

.pagination a {
  padding: 10px 15px;
  margin: 0 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #555;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.pagination a:hover {
  background-color: #4caf50;
  color: white;
}

.pagination a.active {
  background-color: #4caf50;
  color: white;
}

.export-button {
  padding: 10px 20px;
  font-size: 1em;
  color: white;
  background-color: #4caf50;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.export-button:hover {
  background-color: #45a049;
}

/* Additional styles for the Personal Dashboard */
.top-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #4a90e2;
  color: #fff;
  padding: 10px 20px;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.top-header h1 {
  margin: 0;
  font-size: 24px;
  color: yellow;
}

.status-indicator {
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-right: auto;
}

.icons {
  display: flex;
  align-items: center;
}

.icons .icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  cursor: pointer;
}

.button-start-dialer {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  margin-right: 10px;
}

.button-green {
  background-color: #4caf50;
  color: white;
}

.button-red {
  background-color: #f44336;
  color: white;
}

.container {
  display: flex;
}

.menu {
  width: 200px;
  background-color: #2c3e50;
  color: #fff;
  padding: 20px;
  position: sticky;
  top: 60px;
  height: 100vh;
  overflow-y: auto;
}

.menu ul {
  list-style: none;
  padding: 0;
}

.menu ul li {
  margin-bottom: 10px;
}

.menu ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
}

.menu ul li.active a {
  font-weight: bold;
}

.submenu {
  list-style: none;
  padding-left: 20px;
}

.content {
  flex: 1;
  padding: 20px;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 5px;
  margin-right: 10px;
}

.main-container {
  background-color: #e3f2fd;
  padding: 20px;
  border-radius: 10px;
}

.main-content {
  display: flex;
}

.left-column,
.right-column {
  flex: 1;
}

.section-box {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.section-box h2 {
  margin-top: 0;
  margin-bottom: 10px;
}

.stats-box,
.sales-box {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.stats-box h2,
.sales-box h2 {
  margin-top: 0;
  margin-bottom: 10px;
}

.stats-content,
.sales-content {
  background-color: #fff;
  text-align: center;
}

.stats-content .label,
.sales-content .label {
  font-weight: bold;
  color: #4a90e2;
}

.stats-content .value,
.sales-content .value {
  color: #333;
}

.table-container {
  margin-top: 20px;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

.table-container th,
.table-container td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.table-container th {
  cursor: pointer;
}

.toggle-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.toggle-label {
  margin-right: 10px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .toggle-slider {
  background-color: #4a90e2;
}

input:checked + .toggle-slider:before {
  transform: translateX(26px);
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination a {
  margin: 0 5px;
  padding: 10px 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  cursor: pointer;
  border-radius: 5px;
}

.pagination a.active {
  background-color: #4a90e2;
  color: #fff;
  border: 1px solid #4a90e2;
}

.stopwatch {
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  margin-left: 10px;
}

.stopwatch.logged-in {
  color: green;
}

.stopwatch.logged-out {
  color: red;
}

.blue-container {
  background-color: #4a90e2;
  border-radius: 10px;
  padding: 20px;
  width: 50%;
  margin-bottom: 20px;
  text-align: center;
  box-sizing: border-box;
}

.notification {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: white;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.notification-content {
  text-align: center;
}

.dialpad-container {
  position: absolute;
  top: 60px;
  left: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.info-box,
.profile-box,
.notes-box,
.calls-box,
.thread-box {
  background-color: #f4f6f8;
  padding: 10px;
  border-radius: 10px;
}

.info-box table,
.profile-box table,
.notes-box table,
.calls-box table {
  width: 100%;
  border-collapse: collapse;
}

.info-box td,
.profile-box td,
.notes-box td,
.calls-box td {
  padding: 8px;
  border: 1px solid #ddd;
}

.info-box .label,
.profile-box .label,
.notes-box .label,
.calls-box .label {
  font-weight: bold;
  color: #4a90e2;
}

.info-box .value,
.profile-box .value,
.notes-box .value,
.calls-box .value {
  color: #333;
}

.company-name {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #333;
  width: 100%;
}

.owner-info-heading {
  font-size: 18px;
  font-weight: bold;
  color: #4a90e2;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.centered {
  text-align: center;
}

.editable:empty:before {
  content: attr(data-placeholder);
  color: #999;
}
</style>