<template>
  <div>
    <Breadcrumbs main="Project" title="BlackList" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 project-list">
          <div class="card">
            <div class="row m-0">
              <div class="col-sm-12 p-0">
                <b-tabs content-class="mt-3">
                  <b-tab active>
                    <template v-slot:title>
                      <feather type="target"></feather> All
                    </template>
                    <b-card-text>
                      <div class="col-xl-12 col-lg-12 box-col-12">
                        <div class="card">
                          <div class="card-header">
                            <h5>BlackList</h5>
                          </div>

                          <div class="card-body">
                            <div class="row">
                              <div class="col-10">
                                <input
                                  class="form-control"
                                  placeholder="Search by Phone , label, or email"
                                  id="blacklist-search"
                                  v-model="searchTerm"
                                />
                              </div>
                              <div class="col-2">
                                <button class="btn btn-primary" @click="search">
                                  Search
                                </button>
                              </div>
                            </div>
                            <div class="row">
                              <table class="table">
                                <tbody>
                                  <tr>
                                    <th>label</th>
                                    <th>phone1</th>
                                    <th>phone2</th>
                                    <th>mail1</th>
                                    <th>mail2</th>
                                    <th>Time Added</th>
                                    <th>Remove</th>
                                  </tr>
                                  <tr
                                    v-for="contact in searchList"
                                    :key="contact.id"
                                  >
                                    <td>{{ contact.label }}</td>
                                    <td>{{ contact.phone }}</td>
                                    <td>{{ contact.phone2 }}</td>
                                    <td>{{ contact.email }}</td>
                                    <td>{{ contact.email2 }}</td>
                                    <td>{{ contact.time }}</td>
                                    <td>
                                      <i
                                        class="fa fa-ban"
                                        style="color: #f54438"
                                        @click="deleteBlacklist(contact.id)"
                                      ></i>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div
                              class="chart-container"
                              style="margin-top: 100px"
                            >
                              <p>
                                Black List a lead Phone Number
                                <small
                                  >All phone matches with different formats
                                  including country code (EX: +1) are being
                                  blacklisted.</small
                                >
                              </p>
                              <div class="row">
                                <div class="col-lg-10" style="display: flex">
                                  <input
                                    class="form-control"
                                    type="text"
                                    placeholder="Label Name"
                                    id="blacklist-label"
                                    v-model="label"
                                  />
                                  <input
                                    class="form-control"
                                    type="text"
                                    placeholder="Phone number 1"
                                    id="blacklist-no"
                                    v-model="no"
                                  />
                                  <input
                                    class="form-control"
                                    type="text"
                                    placeholder="Phone number 2"
                                    id="blacklist-no2"
                                    v-model="no2"
                                  />
                                  <input
                                    class="form-control"
                                    type="text"
                                    placeholder="Email Address 1"
                                    id="blacklist-mail"
                                    v-model="mail"
                                  />
                                  <input
                                    class="form-control"
                                    type="text"
                                    placeholder="Email Address 2"
                                    id="blacklist-mail2"
                                    v-model="mail2"
                                  />
                                </div>
                                <div class="col-lg-2">
                                  <button
                                    class="btn btn-danger"
                                    style="width: 100%"
                                    @click="addBlackList"
                                  >
                                    Add to blacklist
                                  </button>
                                </div>
                              </div>
                              <br />
                              <div id="black-list-title">
                                Black Listed Numbers (<span
                                  id="blacklist-count"
                                  >{{ blackList.length }}</span
                                >)
                              </div>
                              <table class="table">
                                <tbody>
                                  <tr>
                                    <th>label</th>
                                    <th>phone1</th>
                                    <th>phone2</th>
                                    <th>mail1</th>
                                    <th>mail2</th>
                                    <th>Time Added</th>
                                    <th>Remove</th>
                                  </tr>
                                  <tr
                                    v-for="contact in blackList"
                                    :key="contact.id"
                                  >
                                    <td>{{ contact.label }}</td>
                                    <td>{{ contact.phone }}</td>
                                    <td>{{ contact.phone2 }}</td>
                                    <td>{{ contact.email }}</td>
                                    <td>{{ contact.email2 }}</td>
                                    <td>{{ contact.time }}</td>
                                    <td>
                                      <i
                                        class="fa fa-ban"
                                        style="color: #f54438"
                                        @click="deleteBlacklist(contact.id)"
                                      ></i>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </div>
              <div class="col-sm-6 create-project-btn">
                <div class="text-right">
                  <div class="form-group mb-0 mr-0"></div>
                  <!-- <a class="btn btn-primary" href="">
                    <i data-feather="plus-square"> </i>Create New Project</a
                  > -->
                  <!-- <router-link to="create-project"
                    ><a class="btn btn-primary" href="">
                      <i data-feather="plus-square"> </i>New Blacklist</a
                    ></router-link
                  > -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      userId: localStorage.getItem("userId"),
      searchTerm: "",
      searchList: "",
      blackList: "",
      no: "",
      no2: "",
      label: "",
      mail: "",
      mail2: "",
    };
  },
  mounted() {
    this.getBlacklisted();
  },
  methods: {
    getImgUrl(path) {
      return require("@/assets/images/" + path);
    },
    getBlacklisted() {
      axios
        .get(
          "https://123avatars.com/backend/getAvatarBlacklist.php?u=" +
            this.userId + "&limit=100"
        )
        .then((response) => {
          this.blackList = response.data.list;
          console.log(this.blackList);
        });
    },
    search() {
      let search = this.searchTerm;
      this.searchTerm = "";
      this.searchList = [];
      axios
        .get(
          "https://123avatars.com/backend/getAvatarBlacklist.php?search=" +
            search +
            "&u=" +
            this.userId
        )
        .then((response) => {
          this.searchList = response.data.list;
          console.log(this.searchList);
          this.$toasted.show(" Search Table updated successfully ", {
            theme: "outline",
            position: "top-right",
            type: "success",
            icon: "check",
            duration: 2000,
          });
        });
    },
    addBlackList() {
      axios
        .get(
          "https://123avatars.com/backend/setAvatarBlacklist.php?u=" +
            this.userId +
            "&no=" +
            this.no +
            "&label=" +
            this.label +
            "&no2=" +
            this.no2 +
            "&mail=" +
            this.mail +
            "&mail2=" +
            this.mail2
        )
        .then((response) => {
          this.getBlacklisted();
          this.no = "";
          this.no2 = "";
          this.mail = "";
          this.mail2 = "";
          this.label = "";
          this.$toasted.show(" Added to blacklist successfully ", {
            theme: "outline",
            position: "top-right",
            type: "success",
            icon: "check",
            duration: 2000,
          });
        });
    },
    deleteBlacklist(id) {
      axios
        .get(
          "https://123avatars.com/backend/deleteBlacklist.php?id=" +
            id +
            "&u=" +
            this.userId
        )
        .then((response) => {
          this.getBlacklisted();
          this.$toasted.show(" Deleted Successfully ", {
            theme: "outline",
            position: "top-right",
            type: "success",
            icon: "check",
            duration: 2000,
          });
        });
    },
  },
};
</script>
