<template>
	<svg viewBox="0 0 20 20" class="phoneSVG">
	  <path d="M20 15.796c0 .255-.047.589-.142 1a5.652 5.652 0 0 1-.298.974c-.2.473-.777.975-1.733 1.506-.89.483-1.771.724-2.642.724-.256 0-.505-.017-.746-.05a6.135 6.135 0 0 1-.817-.177 11.63 11.63 0 0 1-.675-.206c-.146-.052-.41-.15-.788-.291-.379-.142-.61-.228-.696-.256a12.864 12.864 0 0 1-2.486-1.18c-1.212-.747-2.464-1.768-3.757-3.06-1.292-1.293-2.313-2.545-3.06-3.757A12.863 12.863 0 0 1 .98 8.537a30.268 30.268 0 0 0-.256-.696 55.94 55.94 0 0 1-.29-.788 11.58 11.58 0 0 1-.207-.675A6.14 6.14 0 0 1 .05 5.56 5.48 5.48 0 0 1 0 4.815c0-.87.241-1.752.724-2.642C1.254 1.217 1.757.64 2.23.44c.237-.104.561-.203.973-.298C3.615.047 3.95 0 4.205 0c.132 0 .232.014.298.043.17.056.421.416.753 1.08.104.18.246.435.426.766.18.332.345.632.497.902.151.27.298.523.44.76.029.038.112.156.249.355.137.2.239.367.305.505.067.137.1.272.1.404 0 .19-.135.426-.405.71-.27.285-.564.545-.88.782a8.437 8.437 0 0 0-.881.753c-.27.265-.405.483-.405.653a.96.96 0 0 0 .07.32c.048.128.088.225.121.29.034.067.1.18.2.342.099.16.153.25.163.27.72 1.297 1.543 2.41 2.471 3.338.928.928 2.041 1.752 3.338 2.471.02.01.11.064.27.164.161.1.275.165.341.199.066.033.163.073.291.12a.961.961 0 0 0 .32.071c.17 0 .388-.135.653-.405a8.51 8.51 0 0 0 .753-.88c.237-.318.497-.611.781-.88.284-.27.521-.406.71-.406.133 0 .268.033.405.1.138.066.306.168.505.305.199.137.317.22.355.249.237.142.49.288.76.44.27.152.57.317.902.497.331.18.587.322.767.426.663.332 1.023.583 1.08.753a.795.795 0 0 1 .042.299Z"/>
	</svg>
</template>

<script>
export default {
   name: 'phoneSVG',
}
</script>