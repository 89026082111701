<template>
    <div class="container" style="margin-top:10%;" >
      <div class="row">
        <div class="col-4">
          <!-- <div class="video-container">
            <video id="localVideo" autoplay muted></video>
            <video id="remoteVideo" autoplay></video>
          </div> -->
          <input type="text" class="form-control" placeholder="Enter phone number or SIP user" v-model="callDestination">
          <div class="dialpad">
            <div class="row" v-for="row in dialpad" :key="row">
              <div class="col" v-for="key in row" :key="key">
                <button class="btn btn-light btn-lg" @click="dialPadPress(key)">{{ key }}</button>
              </div>
            </div>
          </div>
          <button class="btn btn-primary btn-lg mt-3" @click="makeCall">Call</button>
        </div>
        <div class="col-8">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active" id="settings-tab" data-bs-toggle="tab" data-bs-target="#settings" type="button" role="tab" aria-controls="settings" aria-selected="true">Settings</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="media-tab" data-bs-toggle="tab" data-bs-target="#media" type="button" role="tab" aria-controls="media" aria-selected="false">Media settings</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="history-tab" data-bs-toggle="tab" data-bs-target="#history" type="button" role="tab" aria-controls="history" aria-selected="false">History</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="wsMessages-tab" data-bs-toggle="tab" data-bs-target="#wsMessages" type="button" role="tab" aria-controls="wsMessages" aria-selected="false">wsMessages</button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="settings" role="tabpanel" aria-labelledby="settings-tab">
              <form @submit.prevent="connect">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" v-model="includeVideo" id="includeVideo">
                  <label class="form-check-label" for="includeVideo">Include Video</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" v-model="enableDebugging" id="enableDebugging">
                  <label class="form-check-label" for="enableDebugging">Enable Debugging</label>
                </div>
                <div class="mb-3">
                  <label class="form-label">Authentication</label>
                  <div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="credential" value="credential" v-model="authentication">
                      <label class="form-check-label" for="credential">Credential</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="token" value="token" v-model="authentication">
                      <label class="form-check-label" for="token">Token</label>
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="SIP Username" v-model="sipUsername">
                </div>
                <div class="mb-3">
                  <input type="password" class="form-control" placeholder="Password" v-model="password">
                </div>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Caller ID Name" v-model="callerIdName">
                </div>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Caller ID Number" v-model="callerIdNumber">
                </div>
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Client State" v-model="clientState">
                </div>
                <button type="submit" class="btn btn-primary">Connect</button>
              </form>
            </div>
            <div class="tab-pane fade" id="media" role="tabpanel" aria-labelledby="media-tab">Media settings content</div>
            <div class="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history-tab">History content</div>
            <div class="tab-pane fade" id="wsMessages" role="tabpanel" aria-labelledby="wsMessages-tab">wsMessages content</div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { TelnyxRTC } from '@telnyx/webrtc';
  
  export default {
    data() {
      return {
        callDestination: '',
        includeVideo: false,
        enableDebugging: false,
        authentication: 'credential',
        sipUsername: 'cmharrisiii22686',
        password: '5khcqfTE',
        callerIdName: '',
        callerIdNumber: '',
        clientState: '',
        telnyxRTC: null,
        call: null,
        dialpad: [
          ['1', '2', '3'],
          ['4', '5', '6'],
          ['7', '8', '9'],
          ['*', '0', '#']
        ]
      };
    },
    methods: {
      connect() {
        this.telnyxRTC = new TelnyxRTC({
          login: this.sipUsername,
          password: this.password,
          callerIdName: this.callerIdName,
          callerIdNumber: this.callerIdNumber,
          debug: this.enableDebugging,
          logLeveL: 'debug'
        });
  
        this.telnyxRTC.on('telnyx.socket.open', () => {
          console.log('Socket opened');
        });
  
        this.telnyxRTC.on('telnyx.socket.error', (error) => {
          console.error('Socket error', error);
        });
  
        this.telnyxRTC.on('telnyx.socket.close', () => {
          console.log('Socket closed');
        });
  
        this.telnyxRTC.on('telnyx.ready', () => {
          console.log('Telnyx ready');
        });
  
        this.telnyxRTC.on('telnyx.error', (error) => {
          console.error('Telnyx error', error);
        });
  
        this.telnyxRTC.on('telnyx.call.incoming', (call) => {
          this.call = call;
          console.log('Incoming call', call);
        });
  
        this.telnyxRTC.on('telnyx.call.answered', (call) => {
          console.log('Call answered', call);
        });
  
        this.telnyxRTC.on('telnyx.call.hangup', (call) => {
          console.log('Call hung up', call);
        });

        this.telnyxRTC.connect();
      },
      dialPadPress(key) {
        this.callDestination += key;
      },
      makeCall() {
      if (this.telnyxRTC) {
        this.call = this.telnyxRTC.newCall({
          destinationNumber: '12672342397',
          callerNumber: '19495700355'
        });

        this.telnyxRTC.on('telnyx.notification', () => {
          console.log('Call started');
        });


        this.telnyxRTC.on('telnyx.call.started', () => {
          console.log('Call started');
        });

        this.telnyxRTC.on('telnyx.call.ended', () => {
          console.log('Call ended');
        });

        this.telnyxRTC.on('telnyx.call.error', (error) => {
          console.error('Call error:', error);
        });

        this.telnyxRTC.on('telnyx.ice.candidate', (candidate) => {
          console.log('Call ICE candidate:', candidate);
        });

        this.telnyxRTC.on('telnyx.ice.stateChange', (state) => {
          console.log('Call ICE state change:', state);
        });
      }
    }
    }
  };
  </script>
  
  <style scoped>
  .video-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  
  video {
    width: 45%;
    height: auto;
    background-color: black;
  }
  
  .dialpad .row {
    margin-bottom: 0.5rem;
  }
  
  button.btn-lg {
    width: 100%;
  }
  </style>