import {
  CognitoUserPool,
  CognitoUser,
} from "amazon-cognito-identity-js";

// Configuration for your Cognito User Pool
const poolData = {
  UserPoolId: "us-west-1_Xb5sM57hy", // Replace with your actual user pool id
  ClientId: "459g3alqohqvrvrln2ccla7r0v", // Replace with your actual client id
};

// Initialize the Cognito User Pool
const userPool = new CognitoUserPool(poolData);

class CognitoService {
  getCognitoSub() {
    return new Promise((resolve, reject) => {
      const cognitoUser = userPool.getCurrentUser();

      if (cognitoUser) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            console.error('Error retrieving session:', err);
            return reject(null);
          }

          if (session && session.isValid()) {
            const payload = session.getIdToken().payload;
            resolve(payload.sub || null);
          } else {
            resolve(null);
          }
        });
      } else {
        resolve(null);
      }
    });
  }

  getUserRole() {
    return new Promise((resolve, reject) => {
      const cognitoUser = userPool.getCurrentUser();

      if (cognitoUser) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            console.error('Error retrieving session:', err);
            return reject(null);
          }

          if (session && session.isValid()) {
            const payload = session.getIdToken().payload;

            const groups = payload['cognito:groups'];

            if (!groups || groups.length === 0) {
              resolve(null); // No groups found
            } else {
              resolve(groups[0]); // Resolve the first group as the role
            }
          } else {
            resolve(null);
          }
        });
      } else {
        resolve(null);
      }
    });
  }

  signOut() {
    return new Promise((resolve, reject) => {
      const cognitoUser = userPool.getCurrentUser();

      if (cognitoUser) {
        cognitoUser.signOut();
        console.log('User signed out successfully');
        resolve(true);
      } else {
        console.error('No user found to sign out');
        resolve(false);
      }
    });
  }
}

export default new CognitoService();