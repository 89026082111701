<template>
	<div id="TOReports">
	  <UIBox>
		<template v-slot:title>
		  <span class="bold">TO QC Report</span>
		</template>
		<template>
		  <div class="filter">
			<div class="filterHead">Filter by</div>
			<div class="filterBody">
			  <!-- Date Filter -->
			  <div class="filterSelect">

				<label for="dateFilter">Date</label>
				<div>
				<div class="select">
					<select id="dateFilter" v-model="dateFilter" @change="fetchLeads" class="form-control">
					<option value="All">All</option>
					<option value="Today">Today</option>
					<option value="Yesterday">Yesterday</option>
					<option value="This Week">This Week</option>
					<option value="Last Week">Last Week</option>
					<option value="This Month">This Month</option>
					<option value="Last Month">Last Month</option>
					<option value="Last 7 Days">Last 7 Days</option>
					<option value="Last 30 Days">Last 30 Days</option>
					<option value="Last 90 Days">Last 90 Days</option>
					<option value="YTD">YTD</option>
					<option value="Custom">Custom</option>
					</select>
				</div>
				<div v-if="dateFilter === 'Custom'">
					<DatePicker v-model="customStartDate" placeholder="Start Date" class="form-control" :format="'MM-DD-YYYY'" />
					<DatePicker v-model="customEndDate" placeholder="End Date" class="form-control" :format="'MM-DD-YYYY'" />
					<button @click="fetchLeads" class="btn solid apply">Apply</button>
				</div>
				</div>
			  </div>
  

			  <!-- Sales Rep Filter -->
			  <div class="filterSelect select" v-if="role === 'Admins' || role === 'Managers'">
				<label for="shelfLifeFilter">Sales Reps / Operators</label>
				<select id="shelfLifeFilter" v-model="salesRepFilter" @change="fetchLeads" class="form-control">
					<option value="All">All</option>
					<option v-for="rep in salesReps" :value="rep.name">{{ rep.name }}</option>
				</select>
				</div>
				
				<!-- Sales Rep Filter -->
				<div class="filterSelect" v-if="role === 'Admins'">
					<label for="managerFilter">Managers</label>
					
					<!-- Manager Selector Filter -->
					<div class="select"> 
						<select id="managerFilter" v-model="managerFilter" class="form-control " @change="fetchLeads">
							<option value="All">All</option>
							<!-- <option value="Search">&#128269; Search</option> -->
							<option v-for="manager in managers" :value="manager.id">
								{{ manager.name }}
							</option>
						</select>
					</div>
					
					<!-- Select is replaced with the following div if SEARCH is selected -->
					<!-- <div class="filterText">
					  <input type="text" v-model="searchCallBacks" @keyup="" class="form-control outline" placeholder="Search Managers" />
					</div> -->
				</div>

			</div>
		  </div>
  
		  <!-- TO Reports Table -->
		<!-- <template v-if="!to_items || to_items?.length == 0">
			 <div class="empty-message">No TO Reports</div>
		</template> -->
		<template>
			<div v-if="to_items && to_items.length > 0" class="table-fullwidth">
				<b-table 
				:items="to_items" 
				:fields="to_fields" 
				class="stats nopad-last " 
				:per-page="rowsPerPage" 
				:current-page="currentPage" 
				aria-controls="TOReport"
				:row-class="{ 'dispute-background': true}"
				:busy="loading"
				>
				  <!-- Loading slot for busy state -->
				<template #table-busy>
					<div class="text-center text-danger my-2">
					<b-spinner class="align-middle"></b-spinner>
					<strong>Loading...</strong>
					</div>
				</template>
				<template #cell(pass)="pass">
					<div class="passfailicon">
					<CheckSVG class="pass" v-if="pass.value" />
					<CloseSVG class="fail" v-else />
					</div>
				</template>
				<!-- Button column -->
				<template #cell(playback)="data">
					<!-- <button class="play-button" @click="toggleAudio(data.item)"> 
					{{ playingCallId === data.item.id ? 'Pause' : 'Play' }}
					</button> -->
					<VueAudioPlayer
						ref="audioPlayer"
						:audio-list="[{ src: data.item.recordingUrl }]"
						:before-play="handleBeforePlay"
						:show-play-loading="false"
						:theme-color="'#B6E7C3'"
						>
						</VueAudioPlayer>
				</template>
				<template #cell(Dispute)="data" v-if="role !== null">
					<button class="btn btn-primary" @click="disputeTO(data.item)">
					Dispute
					</button>
				</template>
				
				</b-table>
			</div>
			<div v-else class="empty-message">No TO Reports</div>
		</template>
  
		  <!-- Bottom Input -->
		  <div class="bottom-input">
			<div class="per-page">
			  <div class="select">
				Per Page
				<select class="per-page-drop" id="rowsPerPage" v-model="rowsPerPage" @change="fetchLeads">
				  <option value="10">10</option>
				  <option value="25">25</option>
				  <option value="50">50</option>
				  <option value="100">100</option>
				  <option value="500">500</option>
				</select>
			  </div>
			</div>
			<div class="pagin">
			  <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="rowsPerPage" aria-controls="TOReport" @change="fetchLeads">
				  <template #first-text>
					  <PaginleftdoubleSVG />
					</template>
					<template #prev-text>
					  <PaginleftSVG />
					</template>
					<template #next-text>
					  <PaginrightSVG />
					</template>
					<template #last-text>
					  <PaginrightdoubleSVG />
					</template>
			  </b-pagination>
			</div>
		  </div>
		</template>
	  </UIBox>
	</div>
  </template>
  
  <script>
  import axios from 'axios';
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import UIBox from './uibox.vue';
  import PaginleftdoubleSVG from './SVG-icons/paginleftdoubleSVG.vue';
  import PaginleftSVG from './SVG-icons/paginleftSVG.vue';
  import PaginrightdoubleSVG from './SVG-icons/paginrightdoubleSVG.vue';
  import PaginrightSVG from './SVG-icons/paginrightSVG.vue';
  import CloseSVG from './SVG-icons/closeSVG.vue';
  import CheckSVG from './SVG-icons/checkSVG.vue';
  import SearchSVG from './SVG-icons/searchSVG.vue';

  import AuthService from '@/_services/AuthService';
import { initAsyncCompiler } from 'sass';

import VueAudioPlayer from '@liripeng/vue-audio-player'
  
  export default {
	components: {
	  DatePicker,
	  UIBox,
	  PaginleftdoubleSVG,
	  PaginleftSVG,
	  PaginrightdoubleSVG,
	  PaginrightSVG,
	  CloseSVG,
	  CheckSVG,
	  SearchSVG,
	  'VueAudioPlayer': VueAudioPlayer
	},
	data() {
	  return {
		// Filters and pagination
		dateFilter: 'All',
		customStartDate: new Date(),
		customEndDate: new Date(),
		salesReps: [],
		salesRepFilter: 'All',
		rowsPerPage: 10,
		currentPage: 1,
		userId: '',
		role: '',
		totalRows: 0,
		audio: null,
		playingCallId: '',
		managers: [],
		managerFilter: 'All',
		loading: true,
  
		// Table data
		to_fields: [
		  { key: 'pass', class: 'icon', label: 'Pass / Fail' },
		  { key: 'datetime', label: 'Date / Time' }, 
		  { key: 'repname', label: 'Reps Name' },
		  { key: 'managerName', label: 'Managers Name' },
		  { key: 'companyname', label: 'Company Name' },
		  { key: 'phone', label: 'Phone' },
		  { key: 'ownername', label: 'Owners Name' },
		  { key: 'question1', label: 'How long have you been in business?' },
		  { key: 'website', label: 'Website' },
		  { key: 'question2', label: 'Have you ever done Online Marketing before?' },
		  { key: 'question3', label: 'Where do you get most of your clients from?' },
		  { key: 'question4', label: 'Advertising Investment' },
		  { key: 'question5', label: 'Do you WANT more business?' },
		  { key: 'question6', label: 'Would you like free marketing?' },
		  { key: 'question7', label: 'Was Manager on Call?' },
		  { key: 'playback', label: 'Play Recording' },
	      { key: 'calltime', label: 'Call Time' },
		  { key: 'listentime', label: 'Listen Time' },
		  { key: 'bargetime', label: 'Barge Time' },
		  { key: 'bargetimepassfail', label: 'Barge Time Pass / Fail' }
		],
		to_items: [],
		currentAudioName: '',
		// audioList: [
		// 	{
		// 	src: 'http://music.163.com/song/media/outer/url?id=317151.mp3', // Required
		// 	title: 'Audio Title 1', // Optional，Phone lock screen music player display
		// 	artist: 'Artist Name 1', // Optional，Phone lock screen music player display
		// 	album: 'Album Name 1', // Optional，Phone lock screen music player display
		// 	artwork: [
		// 		{
		// 		src: 'https://upload.jianshu.io/users/upload_avatars/1696356/c358e43854eb?imageMogr2/auto-orient/strip|imageView2/1/w/96/h/96/format/webp',
		// 		sizes: '512x512',
		// 		type: 'image/jpg',
		// 		},
		// 	], // Optional，Phone lock screen music player display
		// 	},
		// 	{
		// 	src: 'http://downsc.chinaz.net/Files/DownLoad/sound1/201906/11582.mp3', // Required
		// 	title: 'Audio Title 2', // Optional，Phone lock screen music player display
		// 	},
		// ],
	  };
	},
	computed: {
	  rowsTO() {
		return this.to_items.length;
	  },
	},
	methods: {
		handleBeforePlay(next) {
			// this.currentAudioName =
			// 	this.audioList[this.$refs.audioPlayer.currentPlayIndex].title

			next() // Start play
		},
		formatDate (d) {
			// you could also provide your own month names array
			const months = this.$refs.datePicker.translation.months
			return `${d.getDate().toString().padStart(2, 0)} ${months[d.getMonth()]} ${d.getFullYear()}`
		},
		formattedDate(date) {
		if (!date) return '';
			const d = new Date(date);
			return `${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}-${d.getFullYear()}`;
		},
		toggleAudio(call) {
			if (this.playingCallId === call.id) {
				this.audio.pause();
				this.audio.currentTime = 0;
				this.audio = null;
				this.playingCallId = null;
			} else {
				if (this.audio) {
				this.audio.pause();
				this.audio.currentTime = 0;
				}
				console.log('call: ', call);
				let url = call.recordingUrl;
				if (url) {
				this.audio = new Audio(url);
				this.audio.play();
				this.playingCallId = call.id;
				this.audio.onended = () => {
					this.audio = null;
					this.playingCallId = null;
				};
				} else {  
				alert("No audio URL available");
				}
			}
		},
		rowClass(item) {
			if (item.isDispute) {
				return 'dispute-background';
			}
			return '';
		},
		async disputeTO(item) {
			try {
				console.log('item: ', item.callId);
				const response = await axios.post('https://123avatars.com/v2/dispute-to', {
				callId: item.callId
				});
				// Set the isDispute flag to true for this call
				if(item._rowVariant == 'danger') {
					item._rowVariant = '';
				} else {
					item._rowVariant = 'danger';
				}
				//this.$forceUpdate();
				console.log('Dispute marked successfully:', response.data);
			} catch (error) {
				console.error('Error marking dispute:', error);
			}
		},
		formatPhoneNumber(phoneNumber) {
			phoneNumber = phoneNumber.toString();
			if (phoneNumber.length > 10) {
			phoneNumber = phoneNumber.substring(phoneNumber.length - 10, phoneNumber.length);
			}
			const areaCode = phoneNumber.slice(0, 3);
			const centralOfficeCode = phoneNumber.slice(3, 6);
			const stationCode = phoneNumber.slice(6, 10);
			const formattedPhoneNumber = `(${areaCode}) ${centralOfficeCode}-${stationCode}`;
			return formattedPhoneNumber;
		},
	  async fetchLeads() {
		try {

			this.loading = true;
		  const params = {
			dateFilter: this.dateFilter,
			customStartDate: this.customStartDate,
			customEndDate: this.customEndDate,
			salesRepFilter: this.salesRepFilter,
			managerFilter: this.managerFilter,
			rowsPerPage: this.rowsPerPage,
			currentPage: this.currentPage + 1,
			role: this.role,
			userId: this.userId
		  };
		  
		  const response = await axios.get('https://123avatars.com/v2/to-report', { params });

		  console.log('responseLength: ', response.data.length);
		  
		  this.totalRows = response.data.totalRows;
		  this.to_items = response.data.calls.map(item => ({
			pass: item.pc_qualified_to == 1,
			datetime: new Intl.DateTimeFormat('en-US', {
				timeZone: 'America/Los_Angeles', // Pacific Time Zone
				year: 'numeric',
				month: 'short', // Abbreviated month
				day: 'numeric',
				hour: 'numeric',
				minute: '2-digit', // 2-digit minute
				hour12: true // AM/PM format
				}).format(new Date(item.start_time)),
			repname: item.sales_rep,
			managerName: item.firstName + ' ' + item.lastName,
			companyname: item.company_name,
			phone: this.formatPhoneNumber(item.prospectPhone),
			ownername: item.askedFirstName ? (item.askedFirstName == 1 ? 'Yes' : 'No') : '-',
			question1: item.processed_to.businessDurationAsked,
			website: item.processed_to.websiteAsked,
			question2: item.processed_to.onlineMarketingExperienceAsked,
			question3: item.processed_to.clientSourceAsked,
			question4: item.processed_to.currentAdvertisingInvestmentAsked,
			question5: item.processed_to.wantMoreBusinessAsked,
			question6: item.processed_to.freeMarketingServiceOffered,
			question7: item.processed_to.managerOnCall ? item.processed_to.managerOnCall : '-',
			isDispute: true, // item.isDispute ? item.isDispute : false,
			callId: item.call_id ? item.call_id : 1,
			_rowVariant: this.role !== null && this.role !== 'Operators' && this.role !== 'Sales_Reps' && item.disputed_to == 1 ? 'danger' : '',
			recordingUrl: item.recordingUrl,
			id: item.call_id,
			bargetime: item.actual_barge_time + 's',
			bargetimepassfail: item.actual_barge_time > 120 ? 'Pass' : 'Fail',
			listentime: item.barge_duration + 's',
			calltime: item.call_duration + 's'
		}));
		this.loading = false;
		this.$forceUpdate();
		  //this.callBackData = response.data;
		} catch (error) {
		  console.error('Error fetching TO QC report data:', error);
		}
	},
	  async fetchManagers() {
		try {
		const params = {
			role: this.role,
			userId: this.userId
		};
		let response = null;
		if(this.role === 'Admins') {
			response = await axios.get('https://123avatars.com/v2/users?filter=Managers');
		}
		if(this.role === 'Managers') {
			response = await axios.get(`https://123avatars.com/v2/users?filter=Managers`);
		}
		this.managers = response.data
			.map(rep => ({
				id: rep.sub,
				name: rep.firstName + ' ' + rep.lastName
			}))
			.sort((a, b) => a.name.localeCompare(b.name));
		} catch (error) {
		console.error('Error fetching sales reps:', error);
		}
	},
	  async fetchSalesReps() {
		try {
		const params = {
			role: this.role,
			userId: this.userId
		};
		let response = null;
		if(this.role === 'Admins') {
			response = await axios.get('https://123avatars.com/v2/sales-reps');
		}
		if(this.role === 'Managers') {
			response = await axios.get(`https://123avatars.com/v2/users?filter=Operators&managerId=${this.userId}`);
		}
		this.salesReps = response.data
			.map(rep => ({
				id: rep.id,
				name: rep.firstName + ' ' + rep.lastName
			}))
			.sort((a, b) => a.name.localeCompare(b.name));
		} catch (error) {
		console.error('Error fetching sales reps:', error);
		}
	},
	async initUser() {
		this.role = await AuthService.getUserRole();
		this.userId = await AuthService.getCognitoSub();
	},
	},
	async created() {
	  await this.initUser();
	  if(this.role !== null) {
		this.to_fields.push({ key: 'Dispute', label: 'Dispute TO' });
	  }
	  this.fetchLeads();
	  this.fetchSalesReps();
	  this.fetchManagers();
	},
  };
  </script>
  <style>
.dispute-background {
  background-color: red;
}</style>