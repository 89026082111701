<template>
  <div class="container mt-5">
    <div class="card shadow p-4">
      <div class="d-flex justify-content-between align-items-start">
        <h2 class="h5 fw-bold">Memory</h2>
        <button class="btn btn-link text-decoration-none text-dark" @click="close">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
          </svg>
        </button>
      </div>
      <div class="mt-4">
        <div v-for="(memory, index) in memories" :key="index" class="d-flex justify-content-between align-items-center mb-2">
          <p class="mb-0">{{ memory.text }}</p>
          <button class="btn btn-link text-dark" @click="deleteMemory(index)">
            <img src="https://api.iconify.design/ic:baseline-delete.svg" class="w-6 h-6 opacity-50 hover:opacity-100 transition duration-300" alt="Delete icon">
          </button>
        </div>
      </div>
      <div class="mt-4">
        <button class="btn btn-primary" @click="clearMemories">Clear ChatGPT's memory</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      memories: [],
      lead_id: 123 // Example lead_id, update this accordingly
    };
  },
  mounted() {
    this.fetchMemories();
  },
  methods: {
    fetchMemories() {
      const userId = localStorage.getItem('userId');
      axios.get('http://localhost:3500/v2/memories', {
        params: { userId }
      })
        .then(response => {
          this.memories = response.data;
        })
        .catch(error => {
          console.error('Error fetching memories:', error);
        });
    },
    deleteMemory(index) {
      const memory = this.memories[index];
      const userId = localStorage.getItem('userId');
      axios.delete(`http://localhost:3500/v2/memories/${memory.id}`, {
        data: { userId }
      })
        .then(() => {
          this.memories.splice(index, 1);
        })
        .catch(error => {
          console.error('Error deleting memory:', error);
        });
    },
    clearMemories() {
      const userId = localStorage.getItem('userId');
      axios.delete('http://localhost:3500/v2/memories', {
        data: { lead_id: this.lead_id, userId }
      })
        .then(() => {
          this.memories = [];
        })
        .catch(error => {
          console.error('Error clearing memories:', error);
        });
    },
    close() {
      // Implement close functionality if needed
    }
  }
};
</script>