<template>
	<svg viewBox="0 0 21 16" class="emailSVG">
  	<path d="M10.017 10.017c-.2 0-.467-.067-.767-.217s-.533-.267-.717-.383c-.183-.117-.45-.317-.8-.584-.35-.266-.533-.416-.566-.416-3.184-2.317-5.45-4-6.8-5.034a.902.902 0 0 1-.35-.75v-.75C.017 1.367.2.917.567.55.933.183 1.367 0 1.9 0h16.25c.517 0 .967.183 1.333.55.367.367.55.8.55 1.333v.75a.902.902 0 0 1-.35.75c-1.283.984-3.533 2.667-6.8 5.034-.033 0-.216.133-.566.416-.35.284-.617.467-.8.584-.184.116-.417.25-.717.383s-.55.217-.767.217h-.016Zm9.616-5.034s.167-.083.25-.033c.084.05.134.133.134.233v7.967c0 .517-.184.967-.55 1.333-.367.367-.8.55-1.334.55H1.883c-.516 0-.966-.183-1.333-.55-.367-.366-.55-.8-.55-1.333V5.167c0-.1.05-.184.133-.217.084-.033.184-.033.25.017.867.65 2.867 2.116 6.017 4.416.05.05.233.2.533.434.3.233.534.416.717.533.183.117.417.267.7.433.283.167.567.3.833.367.267.067.534.117.8.117.234 0 .467-.034.7-.1.234-.067.45-.15.667-.234a3.83 3.83 0 0 0 .667-.366c.233-.15.433-.3.583-.417l.55-.417c.2-.15.35-.266.433-.35C16.65 7.167 18.667 5.7 19.6 4.967l.033.016Z"/>
	</svg>
</template>

<script>
export default {
   name: 'emailSVG',
}
</script>