<template>
	<svg viewBox="0 0 22 17">
  	<path d="M21.6803 4.12995c.39-.39.39-.79 0-1.18l-2.65-2.65c-.39-.39-.79-.39-1.18 0l-9.34999 9.4-4.37-4.43c-.39-.39-.79-.39-1.18 0l-2.65 2.65c-.39.39-.39.79 0 1.18l7.61 7.55995c.39.39.79.39 1.18 0L21.6803 4.11995v.01Z""/>
	</svg>
</template>

<script>
export default {
   name: 'checkSVG',
}
</script>