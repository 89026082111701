<template>
  <div class="login-container">
    <div class="login-card">
      <div class="logo">
        <div class="logo-inner">
          <img src="../../assets/images/logo.svg">
        </div>
      </div>
      <div class="auth-container">
        <h2>{{ newPasswordRequired ? 'Set New Password' : forgotPassword ? 'Reset Password' : '' }}</h2>
        <form @submit.prevent="handleSubmit">
          <div v-if="!newPasswordRequired">
            <input class="field1" type="email" v-model="email" required placeholder="Email" />
            <EmailSVG />
          </div>
          <div v-if="newPasswordRequired || !forgotPassword || verificationStep">
            <input class="field2" type="password" v-model="password" required placeholder="Password" />
            <PasswordSVG />
          </div>
          <div v-if="forgotPassword && verificationStep">
            <label for="code">Verification Code:</label>
            <input class="field2" type="text" v-model="verificationCode" required />
          </div>
          <button type="submit">
            {{ forgotPassword ? (verificationStep ? 'Submit New Password' : 'Send Verification Code') : newPasswordRequired ? 'Set New Password' : 'Login' }}
          </button>
          <a v-if="!forgotPassword && !newPasswordRequired" @click="forgotPassword = true">Forgot Password?</a>
        </form>
        <p v-if="errorMessage">{{ errorMessage }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";

import EmailSVG from '../SVG-icons/emailSVG.vue';
import PasswordSVG from '../SVG-icons/passwordSVG.vue';

const poolData = {
  UserPoolId: "us-west-1_Xb5sM57hy", // Replace with your actual user pool id
  ClientId: "459g3alqohqvrvrln2ccla7r0v", // Replace with your actual client id
};

const userPool = new CognitoUserPool(poolData);

export default {

  components: { EmailSVG, PasswordSVG },

  name: "login",
  data() {
    return {
      email: "",
      password: "",
      verificationCode: "",
      errorMessage: "",
      newPasswordRequired: false,
      forgotPassword: false,
      verificationStep: false,
      cognitoUser: null,
    };
  },
  methods: {
    handleSubmit() {
      this.errorMessage = '';
      if (this.forgotPassword) {
        if (this.verificationStep) {
          this.completePasswordReset();
        } else {
          this.sendVerificationCode();
        }
      } else if (this.newPasswordRequired) {
        this.completeNewPassword();
      } else {
        this.login();
      }
    },
    login() {
      const authenticationDetails = new AuthenticationDetails({
        Username: this.email,
        Password: this.password,
      });

      const userData = {
        Username: this.email,
        Pool: userPool,
      };

      const cognitoUser = new CognitoUser(userData);

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          console.log("Login successful:", result);

          // Extract the JWT token and role from the result
          const idToken = result.getIdToken().getJwtToken();
          const accessToken = result.getAccessToken().getJwtToken();
          const refreshToken = result.getRefreshToken().getToken();

          // Assume the role is stored as a custom attribute in the ID token
          const payload = result.getIdToken().payload;
          const userRole = payload['cognito:groups'] ? payload['cognito:groups'][0] : null; // Replace 'custom:role' with your custom attribute key

          // Save the auth data to local storage
          const userData = {
            token: idToken,
            accessToken: accessToken,
            refreshToken: refreshToken,
            role: userRole,
          };
          localStorage.setItem('user', JSON.stringify(userData));
          localStorage.setItem("loggedIn", true);
          localStorage.setItem("userId", 123009);
          localStorage.setItem("username", this.email);
          localStorage.setItem("userType", 1);

          // Redirect to dashboard or another protected route
          this.$router.push({ name: "dashboard" });
          // if (!userRole) {
          //   //window.location = 'localhost:8080/sales';
          //   this.$router.push({ name: "dashboard" });
          // }
          // if (userRole == 'Managers') {
          //   this.$router.push({ name: "dashboard" });
          // }
          // if (userRole == 'Admins') {
          //   this.$router.push({ name: "dashboard" });
          // }
        },
        onFailure: (err) => {
          console.error("Error logging in:", err);
          this.errorMessage = err.message || "An error occurred during login";
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          this.cognitoUser = cognitoUser;
          this.newPasswordRequired = true;
          console.log("New password required:", userAttributes, requiredAttributes);
        },
      });
    },
    completeNewPassword() {
      if (!this.cognitoUser) return;

      this.cognitoUser.completeNewPasswordChallenge(this.password, {}, {
        onSuccess: (result) => {
          console.log("New password set successfully:", result);
          this.newPasswordRequired = false;
          // this.$router.push({ name: "dashboard" });
        },
        onFailure: (err) => {
          console.error("Error setting new password:", err);
          this.errorMessage = err.message || "An error occurred while setting the new password";
        },
      });
    },
    sendVerificationCode() {
      const userData = {
        Username: this.email,
        Pool: userPool,
      };

      const cognitoUser = new CognitoUser(userData);

      cognitoUser.forgotPassword({
        onSuccess: () => {
          console.log("Verification code sent successfully");
          this.verificationStep = true;
          this.errorMessage = "Verification code sent. Please check your email.";
        },
        onFailure: (err) => {
          console.error("Error requesting password reset:", err);
          this.errorMessage = err.message || "An error occurred while requesting a password reset";
        },
      });
    },
    completePasswordReset() {
      const userData = {
        Username: this.email,
        Pool: userPool,
      };

      const cognitoUser = new CognitoUser(userData);

      cognitoUser.confirmPassword(this.verificationCode, this.password, {
        onSuccess: () => {
          console.log("Password reset successful");
          this.errorMessage = "Password reset successful. You can now log in with your new password.";
          this.forgotPassword = false;
          this.verificationStep = false;
        },
        onFailure: (err) => {
          console.error("Error resetting password:", err);
          this.errorMessage = err.message || "An error occurred while resetting your password";
        },
      });
    },
  },
};
</script>