<template>
	<svg viewBox="0 0 42 36" class="chatSVG">
	   <path d="M21 0C9.40363 0 0 6.92535 0 15.475C0 20.968 3.888 25.7867 9.74655 28.5332C7.58764 32.9139 4.51104 36 4.51104 36C10.3406 35.3158 13.6829 32.7367 15.552 30.4184C17.2907 30.7629 19.1164 30.95 21 30.95C32.5964 30.95 42 24.0246 42 15.475C42 6.92535 32.6012 0 21 0Z" />
	 </svg>
</template>

<script>
export default {
   name: 'chatSVG',
}
</script>