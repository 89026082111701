<template>
  <div class="container-custom mt-3">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2>My Personal Dashboard</h2>
      <div>
        <div class="text-right mb-2"><strong>Total Leads: {{ totalCount }}</strong></div>
        <div class="d-flex flex-column">
          <div id="customGPT"></div>
        </div>
        <div class="d-flex flex-column">
          <div></div>
          <button class="btn mb-2" @click="startDialer()" :class="dialerActive ? 'btn-danger' : 'btn-success'">{{ dialerText }}</button>
          <button class="btn btn-success" @click="nextProspect()" v-if="dialerActive && outboundHuman">Next Prospect</button>
        </div>
      </div>
    </div>
    <div class="btn-group mb-3 w-100" role="group">
      <button class="btn btn-success mb-2" @click="openSignUpPage" v-if="signupLink">Signup</button>
      <button class="btn btn-success mb-2 ml-2" @click="openScriptPage" v-if="scriptLink">Script</button>
      <button class="btn btn-success mb-2 ml-2" @click="openQAPage" v-if="qaLink">Q & A</button>
      <button class="btn btn-success mb-2 ml-2" @click="openTrainingLockerPage" v-if="trainingLockerLink">Training Locker</button>
    </div>
    <div v-if="!dialerActive">
      <div class="card-body default-according style-1">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="div" class="bg-secondary">
            <h5 class="mb-0">
              <b-button v-b-toggle.primary_icon_open_close_1>
                <feather type="bar-chart-2"></feather> Filters
              </b-button>
            </h5>
          </b-card-header>
          <b-collapse
            id="primary_icon_open_close_1"
            accordion="my-accordion-icon-open-close"
            role="tabpanel"
          >
            <b-card-body>
              <b-card-text>
                <div class="stats-section-wrapper row">
                  <div class="stats-section mb-2 col">
                    <table class="table table-bordered text-center table-sm custom-table">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Today</th>
                          <th>This Week</th>
                          <th>Last Week</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Talk Time</td>
                          <td>{{ stats.today.talkTime }}</td>
                          <td>{{ stats.thisWeek.talkTime }}</td>
                          <td>{{ stats.lastWeek.talkTime }}</td>
                        </tr>
                        <tr>
                          <td>Total Calls</td>
                          <td>{{ stats.today.totalCalls }}</td>
                          <td>{{ stats.thisWeek.totalCalls }}</td>
                          <td>{{ stats.lastWeek.totalCalls }}</td>
                        </tr>
                        <tr>
                          <td>Inbound Calls</td>
                          <td>{{ stats.today.inboundCalls }}</td>
                          <td>{{ stats.thisWeek.inboundCalls }}</td>
                          <td>{{ stats.lastWeek.inboundCalls }}</td>
                        </tr>
                        <tr>
                          <td>Outbound Calls</td>
                          <td>{{ stats.today.outboundCalls }}</td>
                          <td>{{ stats.thisWeek.outboundCalls }}</td>
                          <td>{{ stats.lastWeek.outboundCalls }}</td>
                        </tr>
                        <tr>
                          <td>Logged In Time</td>
                          <td>{{ stats.today.loginTime }}</td>
                          <td>{{ stats.thisWeek.loginTime }}</td>
                          <td>{{ stats.lastWeek.loginTime }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="stats-section mb-2 col">
                    <table class="table table-bordered text-center table-sm custom-table">
                      <thead>
                        <tr>
                          <th colspan="4">Averages Per Sale</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Talk Time</td>
                          <td>{{ stats.sales.talkTime || 0 }}</td>
                        </tr>
                        <tr>
                          <td>Calls</td>
                          <td>{{ stats.sales.totalCalls || 0 }}</td>
                        </tr>
                        <tr>
                          <td>Texts</td>
                          <td>{{ stats.sales.inboundCalls || 0 }}</td>
                        </tr>
                        <tr>
                          <td>Shelf Life</td>
                          <td>{{ stats.sales.outboundCalls || 0 }}</td>
                        </tr>
                        <tr>
                          <td>Logged In Time</td>
                          <td>{{ stats.sales.loginTime || 0 }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-4 mb-2">
                    <label for="dateFilter">Filter by Date:</label>
                    <select id="dateFilter" v-model="dateFilter" @change="fetchLeads" class="form-control form-control-sm">
                      <option value="All">All</option>
                      <option value="Today">Today</option>
                      <option value="Yesterday">Yesterday</option>
                      <option value="This Week">This Week</option>
                      <option value="Last Week">Last Week</option>
                      <option value="This Month">This Month</option>
                      <option value="Last Month">Last Month</option>
                      <option value="Last 7 Days">Last 7 Days</option>
                      <option value="Last 30 Days">Last 30 Days</option>
                      <option value="Last 90 Days">Last 90 Days</option>
                      <option value="YTD">YTD</option>
                      <option value="Custom">Custom</option>
                    </select>
                    <div class="d-flex mt-2" v-if="dateFilter === 'Custom'">
                      <DatePicker v-model="customStartDate" placeholder="Start Date" class="form-control form-control-sm mr-2" />
                      <DatePicker v-model="customEndDate" placeholder="End Date" class="form-control form-control-sm mr-2" />
                      <button @click="fetchLeads" class="btn btn-success btn-sm">Apply</button>
                    </div>
                  </div>
                  <div class="col-md-4 mb-2">
                    <label for="typeFilter">Filter by Type:</label>
                    <select id="typeFilter" v-model="typeFilter" @change="fetchLeads" class="form-control form-control-sm">
                      <option value="All">All</option>
                      <option value="Inbound All">Inbound All</option>
                      <option value="Outbound Answers">Outbound Answers</option>
                      <option value="Inbound Caller ID Callbacks">Inbound Caller ID Callbacks</option>
                      <option value="Inbound Voicemail Callbacks">Inbound Voicemail Callbacks</option>
                      <option value="Transferred from Avatar">Transferred from Avatar</option>
                      <option value="Main # Call Ins">Main # Call Ins</option>
                    </select>
                  </div>
                  <div class="col-md-4 mb-2">
                    <label for="timezoneFilter">Filter by Timezone:</label>
                    <select id="timezoneFilter" v-model="timezoneFilter" @change="fetchLeads" class="form-control form-control-sm">
                      <option value="All">All</option>
                      <option value="PST">PST</option>
                      <option value="MST">MST</option>
                      <option value="CST">CST</option>
                      <option value="EST">EST</option>
                      <option value="AKST">AKST</option>
                      <option value="HST">HST</option>
                    </select>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-4 mb-2">
                    <label>Search Leads</label>
                    <input
                      type="text"
                      v-model="searchQuery"
                      @keyup="fetchLeads"
                      class="form-control form-control-sm"
                      placeholder="Search by Company Name, Phone Number, Tags"
                    />
                  </div>
                  <div class="col-md-4 mb-2">
                    <label for="shelfLifeFilter">Filter by Shelf Life:</label>
                    <select id="shelfLifeFilter" v-model="shelfLifeFilter" @change="fetchLeads" class="form-control form-control-sm">
                      <option value="All">All</option>
                      <option value="1 Day">1 Day</option>
                      <option value="Under 30 Days">Under 30 Days</option>
                      <option value="Over 30 Days">Over 30 Days</option>
                      <option value="Over 90 Days">Over 90 Days</option>
                      <option value="Over 180 Days">Over 180 Days</option>
                      <option value="Over 365 Days">Over 365 Days</option>
                    </select>
                  </div>
                  <div class="col-md-4 mb-2">
                    <label for="hoursFilter">Filter by Hours:</label>
                    <select id="hoursFilter" v-model="hoursFilter" @change="fetchLeads" class="form-control form-control-sm">
                      <option value="Show Only During Hours">Show Only During Hours</option>
                      <option value="Show After Hours">Show After Hours</option>
                    </select>
                  </div>
                </div>
                <div class="btn-group mb-3 w-100" role="group">
                  <button type="button" class="btn btn-primary mr-2" @click="filterByMilestone('All')">All</button>
                  <button type="button" class="btn btn-primary mr-2" @click="filterByMilestone('Never Contacted')">Never Contacted</button>
                  <button type="button" class="btn btn-primary mr-2" @click="filterByMilestone('Spoke to Prospect')">Spoke to Prospect</button>
                  <button type="button" class="btn btn-primary mr-2" @click="filterByMilestone('Wants to Signup')">Wants to Signup</button>
                  <button type="button" class="btn btn-primary" @click="filterByMilestone('Starred')">Starred</button>
                </div>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-bordered table-hover text-center">
        <thead class="thead-light">
          <tr>
            <th>Milestone</th>
            <th @click="sortTable('last_call_date')">Last Call Date</th>
            <th @click="sortTable('last_call_time')">Last Call Time</th>
            <th @click="sortTable('last_call_length')">Last Call Length</th>
            <th>Play</th>
            <th @click="sortTable('click_date')">Click Date</th>
            <th @click="sortTable('time')">Time</th>
            <th @click="sortTable('lead_type')">Lead Type</th>
            <th @click="sortTable('company_name')">Company Name</th>
            <th @click="sortTable('state')">State</th>
            <th @click="sortTable('phone')">Phone</th>
            <th @click="sortTable('timezone')">Timezone</th>
            <th @click="sortTable('shelf_life')">Shelf Life (Days)</th>
            <th @click="sortTable('local_time')">Local Time</th>
            <th @click="sortTable('total_talk_time')">Total Talk Time</th>
            <th @click="sortTable('texts')">Texts</th>
            <th @click="sortTable('calls')">Calls</th>
            <th>Google Search</th>
            <th>Tags</th>
            <th>Messages</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in filteredRows" :key="index" :class="index == 0 && dialerActive ? 'current-call' : ''">
            <td>
              <select v-model="row.milestone" @change="updateLead(row)" class="form-control form-control-sm">
                <option value="Never Contacted">Never Contacted</option>
                <option value="Spoke to Prospect">Spoke to Prospect</option>
                <option value="Wants to Signup">Wants to Signup</option>
                <option value="Starred">Starred</option>
                <option value="Blacklist">Blacklist</option>
                <option value="Signed Up">Signed Up</option>
              </select>
            </td>
            <td>{{ convertToLocalTime(row.last_call_date) | dateFormat('MM.DD.YYYY') }}</td>
            <td>{{ convertToLocalTimeFormatted(row.start_time) }}</td>
            <td>{{ convertSecondsToMinutes(row.duration) }}</td>
            <td>
              <button class="play-button" @click="toggleAudio(row)">
                {{ playingCallId === row.id ? 'Pause' : 'Play' }}
              </button>
            </td>
            <td>{{ convertToLocalTime(row.click_date) | dateFormat('MM.DD.YYYY') }}</td>
            <td>{{ convertToLocalTimeFormatted(row.time) }}</td>
            <td>{{ row.type }}</td>
            <td>{{ row.company_name }}</td>
            <td>{{ row.state }}</td>
            <td><a :href="'tel:' + row.phone">{{ formatPhoneNumber(row.phone) }}</a></td>
            <td>{{ row.timezone }}</td>
            <td>{{ row.shelf_life }}</td>
            <td>{{ getLocalTime(row.click_date, row.timezone) }}</td>
            <td>{{ convertSecondsToMinutes(row.total_talk_time) }}</td>
            <td>{{ row.texts }}</td>
            <td>{{ row.calls }}</td>
            <td>
              <button class="btn btn-primary btn-sm" @click="searchProspectCompany(row.company_name, row.state, row.phone)">Search</button>
            </td>
            <td>
              <ul class="list-inline">
                <li v-for="(tag, tagIndex) in row.tags" :key="tagIndex" class="badge badge-primary mr-1 mb-1">
                  {{ tag }}
                  <span class="remove-tag ml-1 cursor-pointer" @click="removeTag(index, tagIndex)">x</span>
                </li>
              </ul>
              <input v-model="row.newTag" @keypress.enter="addTag(index)" class="form-control form-control-sm mt-1" placeholder="New Tag" />
            </td>
            <td>
              <button class="btn btn-warning btn-sm" @click="openMessagesPopup(row)">Messages</button>
            </td>
            <td>
              <button class="btn btn-primary btn-sm" @click="openNotesPopup(row.company_name, row.lead_id)">Notes</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-center mt-3">
      <nav>
        <ul class="pagination">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)">&laquo;</a>
          </li>
          <li class="page-item" v-for="page in visiblePages" :key="page" :class="{ active: page === currentPage }">
            <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">&raquo;</a>
          </li>
        </ul>
      </nav>
    </div>
    <div class="d-flex justify-content-between align-items-center mt-3">
      <div>Showing {{ filteredRows.length }} of {{ totalCount }} leads</div>
      <div>
        <label for="rowsPerPage">Rows per page:</label>
        <select id="rowsPerPage" v-model="rowsPerPage" @change="fetchLeads" class="form-control form-control-sm d-inline-block w-auto ml-2">
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="500">500</option>
        </select>
      </div>
    </div>

    <div v-if="showOverlay" class="overlay" @click="closePopup"></div>
    <div v-if="showNotesPopup" class="modal fade show d-block" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ notesCompanyName }}</h5>
            <button type="button" class="close" @click="closePopup"><span>&times;</span></button>
          </div>
          <div class="modal-body">
            <table class="table table-bordered text-center table-sm">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Note</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(note, index) in currentNotes" :key="index">
                  <td>{{ note.date }}</td>
                  <td>{{ note.time }}</td>
                  <td>{{ note.text }}</td>
                  <td><button @click="deleteNote(index, note.id)" class="btn btn-danger btn-sm">Delete</button></td>
                </tr>
              </tbody>
            </table>
            <textarea v-model="newNoteText" placeholder="Add a new note" class="form-control mb-3 form-control-sm"></textarea>
          </div>
          <div class="modal-footer">
            <button @click="saveNote" class="btn btn-success btn-sm">Save</button>
            <button @click="closePopup" class="btn btn-secondary btn-sm">Close</button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showMessagesPopup" class="modal fade show d-block" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ messagesCompanyName }}</h5>
            <button type="button" class="close" @click="closePopup"><span>&times;</span></button>
          </div>
          <div class="modal-body">
            <div class="message-thread">
              <div v-for="(message, index) in messages" :key="index" :class="['alert', 'alert-sm', message.received ? 'alert-secondary' : 'alert-primary']" role="alert">
                <strong>{{ message.time }}</strong>: {{ message.text }}
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button @click="closePopup" class="btn btn-secondary btn-sm">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { TelnyxDevice } from '@telnyx/rtc-sipjs';

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      stats: {
        today: { talkTime: '', totalCalls: 0, inboundCalls: 0, outboundCalls: 0 },
        thisWeek: { talkTime: '', totalCalls: 0, inboundCalls: 0, outboundCalls: 0 },
        lastWeek: { talkTime: '', totalCalls: 0, inboundCalls: 0, outboundCalls: 0 },
        sales: { talkTime: 0, totalCalls: 0, inboundCalls: 0, outboundCalls: 0 }
      },
      searchQuery: '',
      dateFilter: 'All',
      typeFilter: 'All',
      milestoneFilter: 'All',
      timezoneFilter: 'All',
      salesRepFilter: 'All',
      shelfLifeFilter: 'All',
      hoursFilter: 'Show Only During Hours',
      customStartDate: '',
      customEndDate: '',
      rowsPerPage: 50,
      currentPage: 1,
      leads: [],
      totalRows: 0,
      sortDirection: 'asc',
      showOverlay: false,
      showNotesPopup: false,
      showMessagesPopup: false,
      notesCompanyName: '',
      messagesCompanyName: '',
      currentNotes: [],
      newNoteText: '',
      notesData: {},
      filteredRows: [],
      currentLeadId: null,
      totalCount: 0,
      scriptLink: '',
      signupLink: '',
      qaLink: '',
      trainingLockerLink: '',
      dialerActive: false,
      dialerText: 'Start Dialer',
      playingCallId: null,
      audio: null,
      outboundHuman: false
    };
  },
  computed: {
    visiblePages() {
      const pages = [];
      let startPage = Math.max(this.currentPage - 2, 1);
      let endPage = Math.min(startPage + 4, this.totalPages);

      if (endPage - startPage < 4) {
        startPage = Math.max(endPage - 4, 1);
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      return pages;
    },
    _filteredRows() {
      let filtered = this.leads.filter(lead => {
        if (this.searchQuery && !this.matchSearchQuery(lead)) {
          return false;
        }
        if (this.typeFilter !== 'All') {
          if(this.typeFilter == 'Inbound All') {
            if(lead.type != 'Inbound Caller ID Callbacks' && lead.type != 'Inbound Voicemail Callbacks') {
              return false;
            }
          } else if (lead.type !== this.typeFilter) {
            return false;
          }
        }
        if (this.milestoneFilter !== 'All' && lead.milestone !== this.milestoneFilter) {
          return false;
        }
        if (this.timezoneFilter !== 'All' && lead.timezone !== this.timezoneFilter) {
          return false;
        }
        if (this.salesRepFilter !== 'All' && lead.sales_rep !== this.salesRepFilter) {
          return false;
        }
        if (this.shelfLifeFilter !== 'All' && !this.matchShelfLifeFilter(lead.shelf_life)) {
          return false;
        }
        if (this.hoursFilter === 'Show Only During Hours' && !this.isDuringBusinessHours(lead.timezone)) {
          return false;
        }
        if (this.hoursFilter === 'Show After Hours' && this.isDuringBusinessHours(lead.timezone)) {
          return false;
        }
        if(this.dateFilter == 'All') {
          return true;
        } else {
          if (!this.matchDateFilter(lead.last_call_date)) {
          return false;
        }
        }
        return true;
      });
      this.totalRows = filtered.length;
      let sliced = filtered.slice((this.currentPage - 1) * this.rowsPerPage, this.currentPage * this.rowsPerPage);
      this.filteredRows = filtered;
      return sliced;
    },
    totalPages() {
      return Math.ceil(this.totalCount / this.rowsPerPage);
    },
  },
  methods: {
    async loadExternalScript() {
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://cdn.customgpt.ai/js/chat.js')
      document.head.appendChild(recaptchaScript)
      recaptchaScript.onload = async () => {
        await CustomGPT.init({p_id: "36587", p_key: "876cdea81afe212c597fe461d006f0a2"});
        CustomGPT.hide();
      }
    },
    convertToLocalTime(utcDateTime) {
      var local = new Date(utcDateTime);
      return local;
    },
    convertToLocalTimeFormatted(utcDateTime) {
      const local = new Date(utcDateTime);
      let hours = local.getHours();
      const minutes = local.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12;
      const minutesStr = minutes < 10 ? '0' + minutes : minutes;
      return `${hours}:${minutesStr} ${ampm}`;
    },
    async nextProspect() {
      try {
        let userId = '123009';
        let dialerId = this.$route.query.id;
        const response = await axios.post('https://123avatars.com/v2/next-prospect', {
            user_id: userId,
            id: dialerId
          });
      } catch (err) {
        console.error("Error calling next prospect.", err);
      }
    },
    toggleAudio(row) {
      if (this.playingCallId === row.id) {
        this.audio.pause();
        this.audio.currentTime = 0;
        this.audio = null;
        this.playingCallId = null;
      } else {
        if (this.audio) {
          this.audio.pause();
          this.audio.currentTime = 0;
        }
        let url = row.recordingUrl;
        if (url) {
          this.audio = new Audio(url);
          this.audio.play();
          this.playingCallId = row.id;
          this.audio.onended = () => {
            this.audio = null;
            this.playingCallId = null;
          };
        } else {  
          alert("No audio URL available");
        }
      }
    },
    convertSecondsToMinutes(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes} min${minutes !== 1 ? 's' : ''} ${remainingSeconds} sec${remainingSeconds !== 1 ? 's' : ''}`;
    },
    async startDialer() {
      try {
        if(this.dialerActive) {
          this.dialerActive = false;
          this.dialerText = 'Start Dialer';
          let userId = '123009';
          let dialerId = this.$route.query.id;
          let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          const response = await axios.post('https://123avatars.com/v2/logout-dialer', {
            user_id: userId,
            id: dialerId,
            timezone: timezone
          });
        } else {
          this.dialerText = 'Stop Dialer';
          let userId = '123009';
          let dialerId = this.$route.query.id;
          let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          if (!userId) {
            throw new Error('User ID not found in localStorage');
          }
          const response = await axios.post('https://123avatars.com/v2/login-dialer', {
            user_id: userId,
            id: dialerId,
            timezone: timezone
          });
          this.dialerActive = true;
          console.log('Login successful:', response.data);
        }
      } catch (error) {
        console.error('Error logging in to dialer:', error);
      }
    },
    formatPhoneNumber(phoneNumber) {
        phoneNumber = phoneNumber.toString();
        const areaCode = phoneNumber.slice(0, 3);
        const centralOfficeCode = phoneNumber.slice(3, 6);
        const stationCode = phoneNumber.slice(6, 10);
        const formattedPhoneNumber = `(${areaCode}) ${centralOfficeCode}-${stationCode}`;
        return formattedPhoneNumber;
    },
    searchProspectCompany(company_name, state, phone) {
      const url = `https://www.google.com/search?q=${encodeURIComponent(this.formatPhoneNumber(phone))}`;
      window.open(url, '_blank');
    },
    openSignUpPage() {
      window.open(this.signupLink, '_blank');
    },
    openScriptPage() {
      window.open(this.scriptLink, '_blank');
    },
    openQAPage() {
      window.open(this.qaLink, '_blank');
    },
    openTrainingLockerPage() {
      window.open(this.trainingLockerLink, '_blank');
    },
    fetchLeads(id = null) {
      const params = {
        search: this.searchQuery,
        date: this.dateFilter,
        type: this.typeFilter,
        milestone: this.milestoneFilter,
        timezone: this.timezoneFilter,
        salesRep: this.salesRepFilter,
        shelfLife: this.shelfLifeFilter,
        hours: this.hoursFilter,
        startDate: this.customStartDate,
        endDate: this.customEndDate,
        rowsPerPage: this.rowsPerPage,
        currentPage: this.currentPage,
      };
      id = this.$route.query.id;
      if (id) {
        params.id = id;
        axios.get('https://123avatars.com/v2/leads', { params })
        .then(response => {
          this.leads = response.data.leads;
          this.totalRows = response.data.total;
          this.totalCount = response.data.total;
          this.scriptLink = response.data.scriptLink;
          this.signupLink = response.data.signupLink;
          this.qaLink = response.data.qaLink;
          this.trainingLockerLink = response.data.trainingLockerLink;
          this.filteredRows = this._filteredRows();
          if(this.dialerActive && this.filteredRows[0].type === 'Outbound Human') {
            this.outboundHuman = true;
          } else {
            this.outboundHuman = false;
          }
          setTimeout(() => {
            this.fetchLeads();
          },500);
        })
        .catch(error => {
          console.error('Error fetching leads:', error);
          setTimeout(() => {
            this.fetchLeads();
          },500);
        });
      }
    },
    fetchStats() {
      let id = this.$route.query.id;
        if(id) {
          const params = {
          uuid: id
        };
        axios.get('https://123avatars.com/v2/stats', { params })
          .then(response => {
            this.stats.today = response.data.today;
            this.stats.thisWeek = response.data.thisWeek;
            this.stats.lastWeek = response.data.lastWeek;
          })
          .catch(error => {
            console.error('Error fetching stats:', error);
          });
        }
    },
    searchTable() {
      this.fetchLeads();
    },
    filterTable() {
      this.fetchLeads();
    },
    filterByMilestone(milestone) {
      this.milestoneFilter = milestone;
      this.fetchLeads();
    },
    applyCustomDateRange() {
      this.fetchLeads();
    },
    changePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
        this.fetchLeads();
      }
    },
    updateRowsPerPage() {
      this.currentPage = 1;
      this.fetchLeads();
    },
    sortTable(property) {
      const direction = this.sortDirection === 'asc' ? 'desc' : 'asc';
      this.leads.sort((a, b) => {
        let x = a[property];
        let y = b[property];
        if (property === 'date') {
          x = new Date(a.start_time);
          y = new Date(b.start_time);
        }
        if (direction === 'asc') {
          return x > y ? 1 : x < y ? -1 : 0;
        } else {
          return x < y ? 1 : x > y ? -1 : 0;
        }
      });
      this.sortDirection = direction;
      this.filteredRows = this._filteredRows();
    },
    matchSearchQuery(lead) {
      const query = this.searchQuery.toLowerCase();
      return (
        lead.phone.toLowerCase().includes(query) ||
        lead.company_name.toLowerCase().includes(query) ||
        lead.state.toLowerCase().includes(query) ||
        (lead.tags || []).some(tag => tag.toLowerCase().includes(query))
      );
    },
    matchDateFilter(date) {
      const d = new Date(date);
      const today = new Date();
      switch (this.dateFilter) {
        case 'All':
          return false;
        case 'Today':
          let isSame = this.isSameDay(today, d);
          return this.isSameDay(today, d);
        case 'Yesterday':
          const yesterday = new Date();
          yesterday.setDate(today.getDate() - 1);
          return this.isSameDay(yesterday, d);
        case 'This Week':
          return this.isSameWeek(today, d);
        case 'Last Week':
          const lastWeek = new Date();
          lastWeek.setDate(today.getDate() - 7);
          return this.isSameWeek(lastWeek, d);
        case 'This Month':
          return this.isSameMonth(today, d);
        case 'Last Month':
          const lastMonth = new Date();
          lastMonth.setMonth(today.getMonth() - 1);
          return this.isSameMonth(lastMonth, d);
        case 'Last 7 Days':
          const last7Days = new Date();
          last7Days.setDate(today.getDate() - 7);
          return d >= last7Days && d <= today;
        case 'Last 30 Days':
          const last30Days = new Date();
          last30Days.setDate(today.getDate() - 30);
          return d >= last30Days && d <= today;
        case 'Last 90 Days':
          const last90Days = new Date();
          last90Days.setDate(today.getDate() - 90);
          return d >= last90Days && d <= today;
        case 'YTD':
          const startOfYear = new Date(today.getFullYear(), 0, 1);
          return d >= startOfYear && d <= today;
        case 'Custom':
          const startDate = new Date(this.customStartDate);
          const endDate = new Date(this.customEndDate);
          return d >= startDate && d <= endDate;
        default:
          return true;
      }
    },
    isSameDay(date1, date2) {
      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
      );
    },
    isSameWeek(date1, date2) {
      const startOfWeek1 = new Date(date1);
      startOfWeek1.setDate(date1.getDate() - date1.getDay());
      startOfWeek1.setHours(0, 0, 0, 0);
      const startOfWeek2 = new Date(date2);
      startOfWeek2.setDate(date2.getDate() - date2.getDay());
      startOfWeek2.setHours(0, 0, 0, 0);
      return startOfWeek1.getTime() === startOfWeek2.getTime();
    },
    isSameMonth(date1, date2) {
      return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth();
    },
    matchShelfLifeFilter(shelfLife) {
      switch (this.shelfLifeFilter) {
        case '1 Day':
          return shelfLife === 1;
        case 'Under 30 Days':
          return shelfLife < 30;
        case 'Over 30 Days':
          return shelfLife > 30;
        case 'Over 90 Days':
          return shelfLife > 90;
        case 'Over 180 Days':
          return shelfLife > 180;
        case 'Over 365 Days':
          return shelfLife > 365;
        default:
          return true;
      }
    },
    isDuringBusinessHours(timeZone) {
      const timeZoneMapping = {
        EST: 'America/New_York',
        PST: 'America/Los_Angeles',
        CST: 'America/Chicago',
        MST: 'America/Denver',
        HST: 'Pacific/Honolulu',
        AKST: 'America/Anchorage',
      };
      const ianaTimeZone = timeZoneMapping[timeZone];
      if (!ianaTimeZone) {
        throw new Error(`Unrecognized timezone abbreviation: ${timeZone}`);
      }
      const now = new Date();
      const options = {
        timeZone: ianaTimeZone,
        hour: '2-digit',
        hour12: false,
      };
      const formatter = new Intl.DateTimeFormat('en-US', options);
      const parts = formatter.formatToParts(now);
      const hour = parseInt(parts.find(p => p.type === 'hour').value, 10);
      return hour >= 9 && hour < 17;
    },
    getLocalTime(date, timeZone) {
      const timeZoneMapping = {
        EST: 'America/New_York',
        PST: 'America/Los_Angeles',
        CST: 'America/Chicago',
        MST: 'America/Denver',
        HST: 'Pacific/Honolulu',
        AKST: 'America/Anchorage',
      };
      const ianaTimeZone = timeZoneMapping[timeZone];
      if (!ianaTimeZone) {
        return new Date(date).toLocaleTimeString();
      }
      return new Date(date).toLocaleTimeString('en-US', { timeZone: ianaTimeZone });
    },
    updateLead(row) {
      axios.put(`https://123avatars.com/v2/leads/${row.sales_lead_id}`, row)
        .then(response => {
          console.log('Lead updated:', response.data);
        })
        .catch(error => {
          console.error('Error updating lead:', error);
        });
    },
    openNotesPopup(companyName, leadId) {
      this.notesCompanyName = `Notes for ${companyName}`;
      this.currentLeadId = leadId;
      this.showOverlay = true;
      this.showNotesPopup = true;
      const userId = localStorage.getItem('userId');
      axios.get(`https://123avatars.com/v2/notes?leadId=${leadId}&userId=${userId}`)
        .then(response => {
          this.currentNotes = response.data.notes;
        })
        .catch(error => {
          console.error('Error fetching notes:', error);
          this.currentNotes = [];
        });
    },
    openMessagesPopup(row) {
      this.messagesCompanyName = `Messages with ${row.company_name}`;
      this.showOverlay = true;
      this.showMessagesPopup = true;
      this.messages = [];
      const avatarId = row.avatar_id;
      const leadId = row.lead_id;
      axios.get(`https://123avatars.com/backend/lead_conversation.php?avatar_id=${row.avatar_id}&lead_id=${row.lead_id}`, {
        params: {
          avatar_id: avatarId,
          lead_id: leadId
        }
      })
      .then(response => {
        this.messages = response.data;
      })
      .catch(error => {
        console.error('Error fetching messages:', error);
      });
    },
    closePopup() {
      this.showOverlay = false;
      this.showNotesPopup = false;
      this.showMessagesPopup = false;
      this.currentLeadId = null;
    },
    saveNote() {
      if (!this.newNoteText.trim()) return;
      const newNote = {
        userId: localStorage.getItem("userId"),
        date: new Date().toLocaleDateString(),
        time: new Date().toLocaleTimeString(),
        text: this.newNoteText.trim(),
      };
      axios.post(`https://123avatars.com/v2/leads/${this.currentLeadId}/notes`, newNote)
        .then(response => {
          this.currentNotes.push(newNote);
          this.newNoteText = '';
          this.fetchLeads();
        })
        .catch(error => {
          console.error('Error saving note:', error);
        });
    },
    deleteNote(index, noteId) {
      axios.delete(`https://123avatars.com/v2/leads/${this.currentLeadId}/notes/${noteId}`)
        .then(response => {
          this.currentNotes.splice(index, 1);
          this.fetchLeads();
        })
        .catch(error => {
          console.error('Error deleting note:', error);
        });
    },
    addTag(rowIndex) {
      const newTag = this.filteredRows[rowIndex].newTag.trim();
      if (newTag) {
        this.filteredRows[rowIndex].tags.push(newTag);
        this.filteredRows[rowIndex].newTag = '';
        this.updateLead(this.filteredRows[rowIndex]);
      }
    },
    removeTag(rowIndex, tagIndex) {
      this.filteredRows[rowIndex].tags.splice(tagIndex, 1);
      this.updateLead(this.filteredRows[rowIndex]);
    },
  },
  mounted() {
    let config = {
      host: 'sip.telnyx.com',
      port: '7443',
      wsServers: 'wss://sip.telnyx.com:7443',
      displayName: 'Phone User',
      username: 'testuser',
      password: 'testuserPassword',
      stunServers: 'stun:stun.telnyx.com:3478',
      turnServers: {
        urls: ['turn:turn.telnyx.com:3478?transport=tcp'],
        username: 'turnuser',
        password: 'turnpassword',
      },
      registrarServer: 'sip:sip.telnyx.com:7443',
    };

    let device = new TelnyxDevice(config);
    this.loadExternalScript();
    this.fetchLeads();
    this.fetchStats();
  },
};
</script>